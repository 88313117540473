import React, { useEffect, useState } from "react";
import { api } from "../config.js";
import { Navigate, useNavigate } from "react-router-dom";

function Giris() {
  useEffect(() => {
    document.title = "Giriş Yap - Quantum Botnet";
  }, []);

  const [kullaniciAdi, setKullaniciAdi] = useState("");
  const [parola, setParola] = useState("");
  const [hataMesaji, setHataMesaji] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const oturum_kodu = localStorage.getItem("oturum_kodu");
  if (oturum_kodu) {
    return <Navigate to="/" replace />;
  } 

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await fetch(`${api}/api/kullanici_kontrol`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ kullanici_adi: kullaniciAdi, parola: parola }),
      });

      const data = await response.json();

      if (data.success) {
        // Kullanıcı başarılı şekilde giriş yaptı
        localStorage.setItem("oturum_kodu", data.sessionCode);
        navigate("/", { replace: true }); // Ana sayfaya yönlendir
      } else {
        setHataMesaji(data.message);
      }
    } catch (error) {
      console.error("Giriş işlemi hatası:", error);
      setHataMesaji("Sunucu hatası, tekrar deneyiniz.");
    } finally {
      setLoading(false);
    }
  };

  function HataMesaji() {
    return (
      <div
        id="alert"
        className="flex items-center p-4 mb-4 rounded-lg bg-gray-700 text-red-500"
        role="alert"
      >
        <svg
          className="flex-shrink-0 w-4 h-4"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          viewBox="0 0 20 20"
        >
          <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
        </svg>
        <span className="sr-only">Info</span>
        <div className="ms-3 text-sm font-medium">
          Kullanıcı Adı veya Parola Hatalı..
        </div>
      </div>
    );
  }

  return (
    <section className="bg-[#0e1217] min-h-screen">
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
        <a
          href="#s"
          className="flex items-center mb-2 text-2xl font-semibol text-white"
        >
          <img className="w-32 h-32" src="./logo_beyaz.png" alt="logo" />
        </a>
        <div className="w-full rounded-lg shadow border md:mt-0 sm:max-w-md xl:p-0 bg-gray-800 border-gray-700">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <form
              className="space-y-4 md:space-y-6"
              action="#"
              onSubmit={handleSubmit}
            >
              {hataMesaji && <HataMesaji />}
              <div>
                <label
                  htmlFor="uname"
                  className="block mb-2 text-sm font-medium text-white"
                >
                  Kullanıcı Adı
                </label>
                <input
                  type="text"
                  name="uname"
                  id="uname"
                  className="border sm:text-sm rounded-lg block w-full p-2.5 bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500"
                  placeholder="Kullanıcı Adınızı Giriniz.."
                  value={kullaniciAdi}
                  onChange={(e) => setKullaniciAdi(e.target.value)}
                  required
                />
              </div>
              <div>
                <label
                  htmlFor="passwd"
                  className="block mb-2 text-sm font-medium text-white"
                >
                  Parola
                </label>
                <input
                  type="password"
                  name="passwd"
                  id="passwd"
                  placeholder="••••••••"
                  className="border sm:text-sm rounded-lg block w-full p-2.5 bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500"
                  required
                  value={parola}
                  onChange={(e) => setParola(e.target.value)}
                />
              </div>
              <button
                type="submit"
                className="w-full text-white focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center bg-blue-600 hover:bg-blue-700 focus:ring-blue-800 disabled:bg-blue-950"
                disabled={loading}
              >
                {loading ? "Giriş Yapılıyor..." : "Giriş Yap"}
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Giris;
