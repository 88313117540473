import { faRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { api } from "../config.js";

const UstMenu = () => {
  const [kullaniciAdi, setKullaniciAdi] = useState(null);
  const [hataMesaji, setHataMesaji] = useState("");

  useEffect(() => {
    const oturumKodu = localStorage.getItem("oturum_kodu");

    if (oturumKodu) {
      // Sunucudan kullanıcı bilgilerini al
      fetch(`${api}/api/kullanici_bilgi`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ oturum_kodu: oturumKodu }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.success) {
            setKullaniciAdi(data.kullanici_adi); // Kullanıcı adını ayarla
          } else {
            setHataMesaji(data.message || "Kullanıcı bilgisi alınamadı.");
          }
        })
        .catch((error) => {
          console.error("Kullanıcı bilgisi alma hatası:", error);
          setHataMesaji("Sunucu hatası, kullanıcı bilgisi alınamadı.");
        });
    } else {
      setHataMesaji("Oturum kodu bulunamadı.");
    }
  }, []);

  return (
    <div className="flex flex-col w-full h-20 border-b border-gray-700 justify-center items-end p-5">
      <div className="flex flex-row">
        <div className="bg-gray-700 py-2 px-3 border-l border-y border-gray-600 rounded-l-2xl">
          Giriş Yapılan Kullanıcı: <b>{kullaniciAdi || hataMesaji}</b>
        </div>
        <Link
          to={"/cikis"}
          className="bg-red-800 py-2 px-3 rounded-r-2xl border-y border-r border-red-600 hover:bg-red-700 transition-colors"
        >
          <FontAwesomeIcon icon={faRightFromBracket} />
        </Link>
      </div>
    </div>
  );
};

export default UstMenu;
