import SiteLayout from "./layouts/SiteLayout";
import GenelBakis from "./pages/GenelBakis";
import { Routes, Route } from "react-router-dom";
import Giris from "./pages/Giris";
import Cikis from "./pages/Cikis";
import Hata404 from "./pages/Hata404";
import Botlar from "./pages/Botlar";
import GunlukKayitlari from "./pages/GunlukKayitlari";
import Ayarlar from "./pages/Ayarlar";
import Vnc from "./pages/Vnc";
import ApkOlusturucu from "./pages/ApkOlusturucu";
import OturumKapatildi from "./pages/OturumKapatildi";

function App() {
  return (
    <Routes>
      <Route path="/" element={<SiteLayout />}>
        <Route index element={<GenelBakis />} />
        <Route path="genel-bakis" element={<GenelBakis />} />
        <Route path="botlar" element={<Botlar />} />
        <Route path="gunluk-kayitlari" element={<GunlukKayitlari />} />
        <Route path="ayarlar" element={<Ayarlar />} />
        <Route path="apk-olusturucu" element={<ApkOlusturucu />} />
        <Route path="vnc/:idbot" element={<Vnc />} />
      </Route>
      <Route path="oturum-kapatildi" element={<OturumKapatildi />} />
      <Route path="giris" element={<Giris />} />
      <Route path="cikis" element={<Cikis />} />
      <Route path="*" element={<Hata404 />} />
    </Routes>
  );
}

export default App;
