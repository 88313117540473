import React, { useEffect } from "react";
import { Outlet, Navigate, useNavigate } from "react-router-dom";
import YanMenu from "../components/YanMenu";
import UstMenu from "../components/UstMenu";
import SiteContext from "../context/SiteContext";
import { api } from "../config";

const SiteLayout = () => {
  const oturum_kodu = localStorage.getItem("oturum_kodu");
  const navigate = useNavigate();

  useEffect(() => {
    if (oturum_kodu) {
      fetch(`${api}/api/kullanici_bilgi`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ oturum_kodu: oturum_kodu }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (!data.success) {
            navigate("/oturum-kapatildi", { replace: true });
          }
        });
    }
  }, [oturum_kodu, navigate]);

  return !oturum_kodu ? (
    <Navigate to="/giris" replace />
  ) : (
    <div className="flex h-screen overflow-auto bg-gray-100 dark:bg-[#0e1217]">
      <SiteContext>
        <YanMenu />
        <div className="w-full text-white overflow-auto justify-center">
          <UstMenu />
          <div className="flex flex-col p-6 lg:p-12">
            <Outlet />
          </div>
        </div>
      </SiteContext>
    </div>
  );
};

export default SiteLayout;
