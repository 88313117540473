import React, { useEffect } from "react";
import { Link } from "react-router-dom";

const OturumKapatildi = () => {
  useEffect(() => {
    document.title = "Oturumunuz Kapatıldı - Quantum Botnet";
  }, [])
  
  localStorage.removeItem("oturum_kodu");

  return (
    <section className="bg-[#0e1217] min-h-screen">
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
        <a
          href="##"
          className="flex items-center mb-2 text-2xl font-semibol text-white"
        >
          <img className="w-32 h-32" src="./logo_beyaz.png" alt="logo" />
        </a>
        <div className="w-full rounded-lg shadow border md:mt-0 sm:max-w-md xl:p-0 bg-gray-800 border-gray-700">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8 text-white text-center">
            <h1><b>Oturumunuz Sonlandırıldı</b></h1>
            <p className="text-sm text-slate-400" style={{ marginTop: 12 }}>Hesabınız kaldırılmış veya farklı bir cihazdan hesabınıza giriş yapılmış olabilir. İşlemlerinize devam edebilmek için tekrar oturum açmayı deneyin.</p>
            <br/>
            <Link to={"/giris"} className="w-full text-white focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center bg-blue-600 hover:bg-blue-700 focus:ring-blue-800">
                Giriş Yap
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OturumKapatildi;
